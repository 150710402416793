import moment from 'moment';

export function stringCompare(a, b) {
  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
}

export function dateCompare(a, b) {
  return moment(a) - moment(b);
}

export function formatDate(date, format) {
  return moment(date).format(format);
}

export function formatPriceNumber(number, currency = 'AUD') {
  return Number(number)
    ? Number(number).toLocaleString('en-AU', {
        style: 'currency',
        currency,
        trailingZeroDisplay: 'stripIfInteger',
      })
    : `$${number}`;
}

export function redirectIfError(error, errorCodes = [404]) {
  if (error?.originalError?.response && errorCodes.includes(error.originalError.response.status)) {
    // todo: would be better to redirect on error page
    window.location.href = '/';
  }
}

export function capitalizeFirstLetter(str) {
  return str?.length >= 1 ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}

export function wrapStringInDoubleQuotes(str) {
  return `\u201C${str}\u201D`;
}

export function wrapStringInSingleQuotes(str) {
  return `\u2018${str}\u2019`;
}
