import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '@material-ui/core';

const TabPanel = ({ children, value, index, ...rest }) => (
  <Box role="tabpanel" hidden={value !== index} {...rest}>
    {value === index && <Box width="100%">{children}</Box>}
  </Box>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

TabPanel.defaultProps = {
  children: null,
};

export default TabPanel;
