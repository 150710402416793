import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import EveeInput from './EveeInput';
import styles from './styles';

const ComboBox = ({
  value,
  items,
  valueName,
  displayName,
  label,
  placeholder,
  disabled,
  error,
  helperText,
  required,
  fullWidth,
  multiple,
  classes,
  onChange,
  ...rest
}) => {
  const renderValue = () => {
    if (value === null || value === undefined) return value;

    if (value === '') {
      return (
        <Typography noWrap variant="subtitle1">
          {placeholder}
        </Typography>
      );
    }

    if (value instanceof Array) {
      const selectedItems = value.map((i) => {
        const currentItem = items.find((j) => (valueName ? j[valueName] : j) === i);
        if (currentItem) {
          return displayName ? currentItem[displayName] : currentItem;
        }
        return '';
      });

      return (
        <Typography noWrap variant="subtitle1">
          {_.compact(selectedItems).join(', ')}
        </Typography>
      );
    }

    const selectedItem = items.find((j) => (valueName ? j[valueName] : j) === value);

    return selectedItem ? (
      <Typography noWrap variant="subtitle1">
        {displayName ? selectedItem[displayName] : selectedItem}
      </Typography>
    ) : (
      ''
    );
  };

  return (
    <FormControl
      required={required}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      className={classes.container}
    >
      {label && (
        <InputLabel variant="standard" shrink={false} style={{ marginBottom: '4px' }}>
          {label}
        </InputLabel>
      )}

      <Select
        multiple={multiple}
        value={value}
        input={<EveeInput />}
        renderValue={renderValue}
        MenuProps={{ classes: { paper: classes.menu } }}
        inputProps={{ classes: { icon: classes.icon } }}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
      >
        {placeholder.length > 0 && (
          <MenuItem disabled value="">
            <Typography variant="subtitle1">{placeholder}</Typography>
          </MenuItem>
        )}
        {items &&
          items.map((item, index) => (
            <MenuItem
              key={index}
              disabled={item.disabled}
              value={valueName ? item[valueName] : item}
            >
              {multiple && (
                <Checkbox
                  color="primary"
                  checked={value.indexOf(valueName ? item[valueName] : item) > -1}
                />
              )}
              <Typography noWrap variant="subtitle1">
                {displayName ? item[displayName] : item}
              </Typography>
            </MenuItem>
          ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

ComboBox.propTypes = {
  value: PropTypes.any,
  items: PropTypes.array,
  valueName: PropTypes.string,
  displayName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  multiple: PropTypes.bool,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    menu: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func,
};

ComboBox.defaultProps = {
  value: {},
  items: [],
  valueName: '',
  displayName: '',
  label: null,
  placeholder: '',
  disabled: false,
  error: false,
  required: false,
  helperText: '',
  fullWidth: false,
  multiple: false,
  onChange: () => {},
};

export default withStyles(styles)(ComboBox);
