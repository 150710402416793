import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';

import Button from '../Button';
import styles from './styles';

const PillButton = ({ active, children, className, classes, ...rest }) => {
  const buttonClsx = clsx(className, active && classes.activeButton);

  return (
    <Button
      type="outlined"
      color="primary"
      className={buttonClsx}
      classes={{ root: classes.button }}
      {...rest}
    />
  );
};

PillButton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    activeButton: PropTypes.string.isRequired,
  }).isRequired,
};

PillButton.defaultProps = {
  active: false,
  children: null,
  className: '',
};

export default withStyles(styles)(PillButton);
