import { payout as payoutModel } from '@evee/evee-ui.models';

import errorService from '../../errorService';
import makeRequest from '../utils/makeRequest';

const payoutsUrl = 'customers/me/payouts';

const getAll = async (currency) =>
  makeRequest({
    method: 'get',
    url: `${payoutsUrl}`,
    params: { currency },
    errorWrapper: errorService.payout.loadingErr,
  }).then((r) => r.map(payoutModel.parse));

const create = async (payout, currency) =>
  makeRequest({
    method: 'post',
    url: `${payoutsUrl}`,
    params: { currency },
    body: payout,
    errorWrapper: errorService.payout.createErr,
  }).then(payoutModel.parse);

const setDefault = async ({ id, currency }) =>
  makeRequest({
    method: 'post',
    url: `${payoutsUrl}/${id}/default`,
    params: { currency },
    errorWrapper: errorService.payout.updateErr,
  }).then(payoutModel.parse);

const remove = async ({ id, currency }) =>
  makeRequest({
    method: 'delete',
    url: `${payoutsUrl}/${id}`,
    params: { currency },
    errorWrapper: errorService.payout.deleteErr,
  }).then(payoutModel.parse);

const createStripeAccount = async (currency, accountInfo = {}) =>
  makeRequest({
    method: 'post',
    url: `${payoutsUrl}/connect`,
    params: { currency },
    body: accountInfo,
    errorWrapper: errorService.payout.createErr,
  });

const updateBusinessAccount = async (currency, accountInfo = {}) =>
  makeRequest({
    method: 'put',
    url: `${payoutsUrl}/connect`,
    params: { currency },
    body: accountInfo,
    errorWrapper: errorService.payout.updateErr,
  });

export { getAll, create, setDefault, remove, createStripeAccount, updateBusinessAccount };
