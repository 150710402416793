const parseOne = (chatRaw) => {
  if (!chatRaw) {
    return null;
  }

  return {
    ...chatRaw,
    id: chatRaw._id,
    booking: {
      ...chatRaw.booking,
      id: chatRaw.booking._id,
    },
  };
};

const parseChats = (chatsRaw) => {
  if (!chatsRaw.data) {
    return {
      items: [],
      totalPages: 0,
      total: 0,
    };
  }

  return {
    items: chatsRaw.data.map(parseOne),
    totalPages: chatsRaw.totalPages,
    total: chatsRaw.total,
  };
};

export default {
  parseOne,
  parseChats,
};
