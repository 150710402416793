import { location as locationModel } from '@evee/evee-ui.models';

import errorService from '../../errorService';
import makeRequest from '../utils/makeRequest';

const locationsUrl = '/locations';

export const getAirports = async (name, countryCode = 'AU') =>
  makeRequest({
    method: 'get',
    url: `${locationsUrl}/airport`,
    params: { q: name, countryCode },
    errorWrapper: errorService.location.loadingErr,
  }).then((response) => response.map(locationModel.parse));

export const getSearchLocations = async (name) =>
  makeRequest({
    method: 'get',
    url: `${locationsUrl}/search`,
    params: { q: name },
    errorWrapper: errorService.location.loadingErr,
  }).then((response) => response.map(locationModel.parse));

export const getCities = async () =>
  makeRequest({
    method: 'get',
    url: `${locationsUrl}/cities`,
    errorWrapper: errorService.location.loadingErr,
  }).then((response) => response.map(locationModel.parse));

export const getFeatured = async (countryCode) =>
  makeRequest({
    method: 'get',
    url: `${locationsUrl}/featured`,
    params: { countryCode },
    errorWrapper: errorService.location.loadingErr,
  }).then((response) => response.map(locationModel.parse));
