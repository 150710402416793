import PropTypes from 'prop-types';
import React from 'react';

import MuiIconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const IconButton = ({
  icon,
  className,
  href,
  style,
  type,
  color,
  size,
  classes,
  onClick,
  ...rest
}) => (
  <MuiIconButton
    variant={type}
    size={size}
    color={color}
    className={className}
    classes={classes}
    href={href}
    style={style}
    onClick={onClick}
    {...rest}
  >
    {icon}
  </MuiIconButton>
);

IconButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  href: PropTypes.string,
  style: PropTypes.shape({}),
  icon: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    colorPrimary: PropTypes.string.isRequired,
    colorSecondary: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};

IconButton.defaultProps = {
  className: '',
  onClick: () => {},
  type: 'contained',
  color: 'primary',
  size: 'medium',
  style: {},
  href: '',
};

export default withStyles(styles)(IconButton);
