export const save = (data) => {
  localStorage.setItem('evs.user', JSON.stringify(data));
};

export const remove = () => {
  localStorage.removeItem('evs.user');
};

export const get = () => {
  const userString = localStorage.getItem('evs.user');
  return JSON.parse(userString);
};
