import customer from './customer';

const parse = (discountCodeRaw) =>
  discountCodeRaw
    ? {
        id: discountCodeRaw._id,
        referralOwner: customer.parse(discountCodeRaw.referralOwner),
        ...discountCodeRaw,
      }
    : null;

const serialize = (discountCode) => ({
  _id: discountCode.id,
  referralOwner: customer.serialize(discountCode.referralOwner),
  ...discountCode,
});

//TODO: remove ids

export default {
  parse,
  serialize,
};
