export default {
  australia: {
    id: 'AU',
    language: 'English',
    name: 'Australia',
    domain: process.env.REACT_APP_DOMAIN_AU,
    supportDomain: process.env.REACT_APP_SUPPORT_DOMAIN,
  },
  newZealand: {
    id: 'NZ',
    language: 'English',
    name: 'New Zealand',
    domain: process.env.REACT_APP_DOMAIN_NZ,
    supportDomain: process.env.REACT_APP_SUPPORT_DOMAIN,
  },
};
