import PropTypes from 'prop-types';
import React from 'react';

import { Box, Fade } from '@material-ui/core';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '../IconButton';
import styles from './styles';

const ScrollToTop = ({ visible, classes, onClick }) => (
  <Box className={classes.container}>
    <Fade mountOnEnter unmountOnExit in={visible} timeout={500}>
      <Box>
        {' '}
        {/* The fade transition should only be applied on the container */}
        <IconButton
          icon={<VerticalAlignTopIcon fontSize="large" />}
          className={classes.button}
          onClick={onClick}
        />
      </Box>
    </Fade>
  </Box>
);

ScrollToTop.propTypes = {
  visible: PropTypes.bool,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};
ScrollToTop.defaultProps = {
  visible: false,
  onClick: () => {},
};

export default withStyles(styles)(ScrollToTop);
