import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Button from '../Button';

import styles from './styles';

const LoadMoreButton = ({ justify, classes, onClick, ...rest }) => (
  <Box display="flex" justifyContent={justify}>
    <Button
      text="Load more"
      type="outlined"
      color="primary"
      size="large"
      className={classes.button}
      onClick={onClick}
      {...rest}
    />
  </Box>
);

LoadMoreButton.propTypes = {
  justify: PropTypes.string,
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};
LoadMoreButton.defaultProps = {
  justify: 'center',
  onClick: () => {},
};

export default withStyles(styles)(LoadMoreButton);
