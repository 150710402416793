import { createErrorWrapper } from '../utils';

export const LOADING_ERROR = 'PAYMENT_METHOD.LOADING_ERROR';
export const UPDATE_ERROR = 'PAYMENT_METHOD.UPDATE_ERROR';
export const CREATE_ERROR = 'PAYMENT_METHOD.CREATE_ERROR';
export const DELETE_ERROR = 'PAYMENT_METHOD.DELETE_ERROR';

export const loadingErr = createErrorWrapper(LOADING_ERROR, 'Failed to load payment methods');
export const updateErr = createErrorWrapper(LOADING_ERROR, 'Failed to update payment method');
export const createErr = createErrorWrapper(LOADING_ERROR, 'Failed to create payment method');
export const deleteErr = createErrorWrapper(LOADING_ERROR, 'Failed to delete payment method');
