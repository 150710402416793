import errorService from '../../errorService';
import makeRequest from '../utils/makeRequest';

const staticPageUrl = '/staticPage';

export const get = async (name) =>
  makeRequest({
    method: 'get',
    url: `${staticPageUrl}/${name}`,
    errorWrapper: errorService.common.loadingErr,
  });
