import _ from 'lodash';

const parseOne = (bookingRaw) => {
  if (!bookingRaw) {
    return null;
  }

  return {
    ...bookingRaw,
    id: bookingRaw._id,
    totalRental: _.sumBy(bookingRaw.bookingDaysPrices, 'priceBeforeDiscount'),
    totalDiscount: _.sumBy(bookingRaw.bookingDaysPrices, 'discountAmount'),
    totalPayoutBeforeFee: _.sumBy(bookingRaw.bookingDaysPrices, 'price'),
    totalFee: _.sumBy(bookingRaw.bookingDaysPrices, 'fee'),
  };
};

const parseBooking = (bookingRaw) => {
  const { bookings, ...vehicle } = bookingRaw;
  return { bookings: bookings.map(parseOne), vehicle };
};

const parse = (bookingsRaw) => (bookingsRaw ? bookingsRaw.map(parseBooking) : []);

const parseTrips = (tripsRaw) => {
  if (!tripsRaw.data) {
    return {
      items: [],
      totalPages: 0,
      total: 0,
    };
  }

  return {
    items: tripsRaw.data.map(parseOne),
    totalPages: tripsRaw.totalPages,
    total: tripsRaw.total,
  };
};

const parseStats = (statsRaw) => {
  const stats = {
    all: 0,
    upcoming: 0,
    ongoing: 0,
    requested: 0,
    completed: 0,
    cancelled: 0,
  };

  const reduceCount = (a, b) => a + b.count;

  if (statsRaw.all) {
    stats.all = statsRaw.all.reduce(reduceCount, 0);
  }

  if (statsRaw.upcoming) {
    stats.upcoming = statsRaw.upcoming.reduce(reduceCount, 0);
  }

  if (statsRaw.ongoing) {
    stats.ongoing = statsRaw.ongoing.reduce(reduceCount, 0);
  }

  if (statsRaw.requested) {
    stats.requested = statsRaw.requested.reduce(reduceCount, 0);
  }

  if (statsRaw.completed) {
    stats.completed = statsRaw.completed.reduce(reduceCount, 0);
  }

  if (statsRaw.cancelled) {
    stats.cancelled = statsRaw.cancelled.reduce(reduceCount, 0);
  }

  return stats;
};

export default {
  parseOne,
  parseBooking,
  parse,
  parseTrips,
  parseStats,
};
