import PropTypes from 'prop-types';
import React from 'react';

import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    color: theme.palette.secondary.contrastText,
    lineHeight: 1.5,
  },
});

const CheckBox = ({ checked, label, color, onChange, classes, ...rest }) => {
  const checkboxJsx = (
    <Checkbox
      color={color}
      checked={checked}
      classes={{ root: classes.root }}
      onChange={(e) => onChange(e.target.checked)}
      {...rest}
    />
  );
  return (
    <FormControlLabel
      control={checkboxJsx}
      color="primary"
      label={<Typography className={classes.label}>{label}</Typography>}
    />
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
  onChange: PropTypes.func,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

CheckBox.defaultProps = {
  checked: false,
  label: '',
  color: 'primary',
  onChange: () => {},
};

export default withStyles(styles)(CheckBox);
