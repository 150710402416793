import { loadScript } from '@evee/evee-ui.utils';

const GOOGLE_MAPS_SCRIPT_ID = 'google-maps';
const GAPI_SCRIPT_SRC = 'https://apis.google.com/js/platform.js';
const GOOGLE_MAPS_SCRIPT_SRC = 'https://maps.googleapis.com/maps/api/js';
const GAPI_SCRIPT_ID = 'gapi';

let mapsApi;
let autocompleteService;
let placesService;
let gapi;

export const init = async () => {
  try {
    await loadScript(
      `${GOOGLE_MAPS_SCRIPT_SRC}?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=Function.prototype`,
      GOOGLE_MAPS_SCRIPT_ID,
    );
    mapsApi = window.google.maps;
    autocompleteService = new window.google.maps.places.AutocompleteService();
    placesService = new window.google.maps.places.PlacesService(document.createElement('div'));

    await loadScript(GAPI_SCRIPT_SRC, GAPI_SCRIPT_ID);
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({ client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID });
      gapi = window.gapi.auth2;
    });
  } catch (error) {
    console.log(error);
  }
};

export const getApis = () => ({
  mapsApi,
  autocompleteService,
  placesService,
  gapi,
});
