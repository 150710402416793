const styles = (theme) => ({
  button: {
    ...theme.typography.body2,
    border: `1px solid ${theme.palette.border.main}`,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadiusLarge,
  },
  activeButton: {
    fontWeight: 500,
    border: `1px solid ${theme.palette.primary.main}`,
  },
});

export default styles;
