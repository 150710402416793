import * as yup from 'yup';

const billingAddressSchema = yup
  .object()
  .shape({
    country: yup.string(),
    street: yup.string(),
    suburb: yup.string(),
    postcode: yup.string(),
    state: yup.string(),
    latitude: yup.number(),
    longitude: yup.number(),
  });

const parse = (paymentMethodRaw) => {
  if (!paymentMethodRaw) {
    return null;
  }

  return {
    id: paymentMethodRaw._id,
    ...paymentMethodRaw,
  };
};

const parseCreate = (paymentMethodRaw) => {
  if (!paymentMethodRaw) {
    return null;
  }

  return {
    id: paymentMethodRaw._id,
    ...paymentMethodRaw.card,
  };
};

const serialize = (paymentMethod) => {
  if (!paymentMethod) {
    return null;
  }

  return {
    _id: paymentMethod.id,
    ...paymentMethod,
  };
};

export default {
  billingAddressSchema,
  parse,
  parseCreate,
  serialize,
};
