import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Fade } from '@material-ui/core';
import { InView } from 'react-intersection-observer';

const LazyContent = ({ className, unmountOnExit, children, ...rest }) => {
  const [visible, setVisible] = useState(false);

  const inViewChanged = (inView) => {
    if (visible && !unmountOnExit) {
      return;
    }
    setVisible(inView);
  };

  return (
    <InView as="div" className={className} onChange={inViewChanged} {...rest}>
      <Fade mountOnEnter unmountOnExit={unmountOnExit} in={visible} timeout={500}>
        {children}
      </Fade>
    </InView>
  );
};

LazyContent.propTypes = {
  children: PropTypes.node,
  unmountOnExit: PropTypes.bool,
  className: PropTypes.string,
};

LazyContent.defaultProps = {
  children: <div />,
  unmountOnExit: false,
  className: '',
};

export default LazyContent;
