import PropTypes from 'prop-types';
import React from 'react';

import MaterialMenu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';

const BOTTOM_DIRECTION = 'bottom';
const RIGHT_DIRECTION = 'right';

const StyledMenu = withStyles({
  list: {
    paddingTop: '0',
    paddingBottom: '0',
  },
  paper: {
    boxShadow:
      '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
  },
})(MaterialMenu);

const Menu = ({ anchorEl, open, children, onClose, openDirection, align, ...rest }) => (
  <StyledMenu
    keepMounted
    id="menu"
    anchorEl={anchorEl}
    open={open}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={
      openDirection === BOTTOM_DIRECTION
        ? {
            vertical: 'bottom',
            horizontal: 'left',
          }
        : {
            vertical: 'center',
            horizontal: 'right',
          }
    }
    transformOrigin={
      openDirection === BOTTOM_DIRECTION
        ? {
            vertical: 'top',
            horizontal: align,
          }
        : {
            vertical: 'center',
            horizontal: align,
          }
    }
    onClose={onClose}
    {...rest}
  >
    {children}
  </StyledMenu>
);

Menu.propTypes = {
  open: PropTypes.bool,
  align: PropTypes.string,
  anchorEl: PropTypes.instanceOf(Element),
  onClose: PropTypes.func,
  openDirection: PropTypes.oneOf([BOTTOM_DIRECTION, RIGHT_DIRECTION]),
  children: PropTypes.node.isRequired,
};

Menu.defaultProps = {
  open: false,
  anchorEl: null,
  align: 'center',
  openDirection: RIGHT_DIRECTION,
  onClose: () => {},
};

export default Menu;
