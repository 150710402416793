import PropTypes from 'prop-types';
import React from 'react';

import { google } from '@evee/evee-ui.services';

import AsyncAutocomplete from '../AsyncAutocomplete';
import OptionView from './OptionView';

function GooglePlacesAutocomplete({
  className,
  value,
  onChange,
  label,
  TextFieldProps,
  InputLabelProps,
  required,
  error,
  helperText,
  classes,
}) {
  const proccessChange = async (prediction) => {
    const place = await google.places.getPlaceDetails(prediction.place_id);
    place.description = prediction.description;
    onChange(place);
  };

  return (
    <AsyncAutocomplete
      requestMethod={google.places.getPlacePredictions}
      value={value}
      className={className}
      displayField="description"
      renderOption={OptionView}
      label={label}
      TextFieldProps={TextFieldProps}
      InputLabelProps={InputLabelProps}
      required={required}
      error={error}
      helperText={helperText}
      classes={{ input: classes.input }}
      onChange={proccessChange}
    />
  );
}

GooglePlacesAutocomplete.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({}),
  onChange: PropTypes.func,
  label: PropTypes.string,
  TextFieldProps: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  InputLabelProps: PropTypes.shape({}),
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  classes: PropTypes.shape({ input: PropTypes.string }),
};

GooglePlacesAutocomplete.defaultProps = {
  className: '',
  value: {},
  label: null,
  required: false,
  error: false,
  helperText: '',
  TextFieldProps: {
    placeholder: '',
  },
  InputLabelProps: {},
  classes: {
    input: '',
  },
  onChange: () => {},
};

export default GooglePlacesAutocomplete;
