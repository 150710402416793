import PropTypes from 'prop-types';
import React from 'react';

import { Fade } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LoaderIcon from '../images/evee-loader.svg';
import styles from './styles';

const Loader = ({ classes, children, loading, showAnimation, transparent }) => (
  <div className={classes.root}>
    {children}
    <Fade in={loading}>
      <div
        className={classes.container}
        style={{ backgroundColor: transparent ? 'transparent' : '#fff' }}
      >
        {showAnimation && <img alt="" src={LoaderIcon} className={classes.svg} />}
      </div>
    </Fade>
  </div>
);

Loader.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  transparent: PropTypes.bool,
  showAnimation: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    svg: PropTypes.string.isRequired,
  }).isRequired,
};

Loader.defaultProps = {
  transparent: false,
  showAnimation: true,
};

export default withStyles(styles)(Loader);
