import React from 'react';

import { InputBase } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const EveeInput = (props) => <InputBase {...props} />;

export default withStyles(styles)(EveeInput);
