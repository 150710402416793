import CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Hex.parse('mVym5oa9xtxGm0ytmfYBxkEdoXyGmyXI');
const iv = CryptoJS.enc.Hex.parse('bQUwbSWKMxwl7x29PGpfbw0PbFtdbkv3');

export const encrypt = (value) => CryptoJS.AES.encrypt(
  value,
  key,
  { iv },
).toString();

export const decrypt = (encrypted) => CryptoJS.AES.decrypt(
  encrypted,
  key,
  { iv },
).toString(CryptoJS.enc.Utf8);
