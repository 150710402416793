import { changeRequest as changeRequestModel } from '@evee/evee-ui.models';
import { formatDateApiUtc } from '@evee/evee-ui.utils';

import makeRequest from '../utils/makeRequest';

const bookingsUrl = '/bookings';

export const create = async (
  bookingId,
  { from, to, total, payout, bookingDaysPrices, days, fee },
  currency,
  message,
) =>
  makeRequest({
    method: 'post',
    url: `${bookingsUrl}/${bookingId}/changeRequest`,
    params: { currency, message },
    body: {
      from: formatDateApiUtc(from),
      to: formatDateApiUtc(to),
      total,
      payout,
      bookingDaysPrices,
      days,
      fee,
    },
  }).then(changeRequestModel.parse);

export const get = async (bookingId, currency) =>
  makeRequest({
    method: 'get',
    url: `${bookingsUrl}/${bookingId}/changeRequest/`,
    params: { currency },
  }).then(changeRequestModel.parse);

export const cancel = async (bookingId, requestId, currency) =>
  makeRequest({
    method: 'put',
    url: `${bookingsUrl}/${bookingId}/changeRequest/${requestId}/cancel`,
    params: {
      currency,
    },
  }).then(changeRequestModel.parse);

export const decline = async (bookingId, requestId, currency) =>
  makeRequest({
    method: 'put',
    url: `${bookingsUrl}/${bookingId}/changeRequest/${requestId}/decline`,
    params: {
      currency,
    },
  }).then(changeRequestModel.parse);

export const accept = async (bookingId, requestId, currency) =>
  makeRequest({
    method: 'put',
    url: `${bookingsUrl}/${bookingId}/changeRequest/${requestId}/accept`,
    params: {
      currency,
    },
  }).then(changeRequestModel.parse);

export const getTotals = async ({ bookingId, from, to, currency }) =>
  makeRequest({
    method: 'get',
    url: `${bookingsUrl}/${bookingId}/changeRequest/totals`,
    params: {
      from: formatDateApiUtc(from),
      to: formatDateApiUtc(to),
      currency,
    },
  }).then(changeRequestModel.parseTotals);

export default {
  create,
  get,
  cancel,
  decline,
  accept,
  getTotals,
};
