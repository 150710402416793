const parse = (reviewRaw) =>
  reviewRaw
    ? {
        id: reviewRaw._id,
        ...reviewRaw,
      }
    : null;

const serialize = (review) =>
  review
    ? {
        _id: review.id,
        ...review,
      }
    : null;

export default {
  parse,
  serialize,
};
