const styles = (theme) => ({
  container: {
    zIndex: 100,
  },
  button: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.7,
    '&:hover': {
      boxShadow: '0 10px 20px 0 rgba(61, 158, 236, 0.2)',
      backgroundColor: theme.palette.secondary.main,
      opacity: 1,
    },
  },
});

export default styles;
