import { createErrorWrapper } from '../utils';

export const LOADING_ERROR = 'VEHICLE.LOADING_ERROR';
export const UPDATING_ERROR = 'VEHICLE.UPDATING_ERROR';
export const CREATING_ERROR = 'VEHICLE.CREATING_ERROR';
export const TOTALS_ERROR = 'VEHICLE.TOTALS_ERROR';
export const OCCUPIED_DAYS_ERROR = 'VEHICLE.OCCUPIED_DAYS_ERROR';

export const loadingErr = createErrorWrapper(LOADING_ERROR, 'Failed to load vehicles');
export const updatingErr = createErrorWrapper(UPDATING_ERROR, 'Failed to update vehicle');
export const creatingErr = createErrorWrapper(CREATING_ERROR, 'Failed to create vehicle');
export const totalsErr = createErrorWrapper(TOTALS_ERROR, 'Failed to get totals');
export const occupiedDaysErr = createErrorWrapper(OCCUPIED_DAYS_ERROR, 'Failed to get occupied days');
