import { vehicle as vehicleModel } from '@evee/evee-ui.models';

import errorService from '../../errorService';
import makeRequest from '../utils/makeRequest';

const baseSearchUrl = '/search';

const search = async (queryParams, currency) =>
  makeRequest({
    method: 'get',
    url: baseSearchUrl,
    params: { ...queryParams, currency },
    errorWrapper: errorService.search.serachErr,
  }).then((r) => ({ ...r, vehicles: r.vehicles.map(vehicleModel.parse) }));

const searchAirports = async (queryParams) =>
  makeRequest({
    method: 'get',
    url: `${baseSearchUrl}/airports`,
    params: queryParams,
    errorWrapper: errorService.search.serachErr,
  });

const getFilters = async () =>
  makeRequest({
    method: 'get',
    url: '/filters',
    errorWrapper: errorService.search.getFiltersErr,
  });

export { search, searchAirports, getFilters };
