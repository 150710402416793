export enum ExtraPaymentStatuses {
  draft = 'Draft',
  waiting = 'Waiting',
  cancelled = 'Canceled',
  declined = 'Declined',
  accepted = 'Accepted',
  chargeFailed = 'Charge Failed',
  payoutProcessing = 'Payout Processing',
  payoutFailed = 'Payout Failed',
  paid = 'Paid',
}
