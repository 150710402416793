import * as yup from 'yup';

const requiredMsg = 'required';
const addressShape = {
  country: yup.string(),
  street: yup.string(),
  suburb: yup.string(),
  postcode: yup.string(),
  state: yup.string(),
  latitude: yup.number(),
  longitude: yup.number(),
};

function transformNumberValue(value, original) {
  if (this.isType(value)) return value;
  return original === '' || original === null || Number.isNaN(original) ? 0 : value;
}

const addressSchema = yup
  .object()
  .shape(addressShape)
  .test(
    'isValidAddress',
    'full address required',
    (value) =>
      value && value.street && value.suburb && value.postcode && value.latitude && value.longitude,
  )
  .test(
    'isValidCountry',
    ({ value }) => `Your vehicle can't be located in ${value.country}`,
    (value, testContext) => {
      const { region } = testContext.options.context;
      return value?.countryCode ? value.countryCode === region.id : true;
    },
  );

const shortAddressSchema = yup
  .object()
  .shape(addressShape);

const listingSchema = yup.object().shape({
  vehicleId: yup.string(),
  status: yup.string(),
  price: yup
    .number()
    .nullable()
    .transform(transformNumberValue)
    .min(
      30,
      'This is below the minimum price of $30 per day. For more information, contact evee support',
    )
    .max(9999)
    .integer()
    .required(requiredMsg),
  distanceIncluded: yup
    .number()
    .nullable()
    .transform(transformNumberValue)
    .min(
      100,
      'This is below the minimum included distance of 100km per day. For more information, contact evee support',
    )
    .max(9999)
    .integer(),
  pricePerKm: yup
    .number()
    .nullable()
    .transform(transformNumberValue)
    .min(0)
    .max(
      1.5,
      'This is above the maximum price of $1.5 per km per. For more information, contact evee support',
    ),
  deliveryPrice: yup
    .number()
    .nullable()
    .transform(transformNumberValue)
    .max(9999)
    .integer()
    .notRequired(),
  make: yup.string().nullable().required(requiredMsg),
  model: yup.string().nullable().required(requiredMsg),
  badge: yup.string().nullable(),
  year: yup.number().required(requiredMsg),
  registrationNumber: yup
    .string()
    .nullable()
    .when('insuranceConfirmed', {
      is: true,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema
          .test(
            'isNotEmptyString',
            requiredMsg,
            (value) => value?.length && value.trim().length > 0,
          )
          .matches('^[A-Z0-9]*$', 'Uppercase letters and digits only.')
          .max(10)
          .required(requiredMsg),
    }),
  odometer: yup
    .number()
    .transform(transformNumberValue)
    .min(0)
    .max(100000000)
    .integer()
    .notRequired(),
  marketValue: yup
    .number()
    .transform(transformNumberValue)
    .max(150000)
    .integer()
    .positive('Accepted values from $1 to $150000')
    .required(requiredMsg),
  deliveryAddress: addressSchema.nullable().default(null).required(requiredMsg),
  photos: yup.array().min(1, 'at least one photo is required').required(requiredMsg),
  extraLocations: yup.array().of(addressSchema).notRequired(),
  realDrivingRange: yup
    .number()
    .nullable()
    .transform(transformNumberValue)
    .min(100, 'This is below the minimum range of 100km')
    .max(1000, 'This is above the maximum range of 1000km')
    .integer()
    .required(requiredMsg),
  compatibleChargingPlugs: yup.array(),
  returnChargeLevel: yup.number().nullable().transform(transformNumberValue).required(requiredMsg),
  freeFastCharging: yup.boolean().nullable().default(false).required(requiredMsg),
  fastChargingProviders: yup.array().when('freeFastCharging', {
    is: true,
    then: (schema) => schema.min(1, 'select at least one provider').required(requiredMsg),
    otherwise: (schema) => schema.notRequired(),
  }),
  description: yup.string().nullable().min(500).required(requiredMsg),
});

const parse = (listing) => {
  if (!listing) {
    return null;
  }

  return {
    ...listing,
    id: listing._id,
    deliveryAddress: null,
  };
};

const serialize = (listing) => ({
  _id: listing.id,
  ...listing,
});

export default {
  addressSchema,
  shortAddressSchema,
  listingSchema,
  parse,
  serialize,
};
