import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import { Box, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const getReviewsStr = (reviewsCount) => (reviewsCount === 1 ? 'review' : 'reviews');
const getReviewsCountStr = (reviewsCount, reviewsStr) =>
  `${reviewsCount}${reviewsStr ? `${' '}${reviewsStr}` : ''}`;

const RatingDetails = ({ value, reviewsCount, renderCount, classes, ...rest }) => {
  const reviewsCountStr = getReviewsCountStr(reviewsCount, getReviewsStr(reviewsCount));
  const iconClsx = clsx(value > 0 ? classes.icon : classes.greyIcon);

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" {...rest}>
      <StarIcon className={iconClsx} fontSize="small" />

      <Typography color="inherit" variant="body2" className={classes.rating}>
        {parseFloat(value || 0).toFixed(1)}
      </Typography>

      <Typography color="inherit" variant="body2" className={classes.reviews}>
        {renderCount ? <>&nbsp;({renderCount(reviewsCountStr)})</> : <>&nbsp;({reviewsCountStr})</>}
      </Typography>
    </Box>
  );
};

RatingDetails.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reviewsCount: PropTypes.number,
  renderCount: PropTypes.func,
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    greyIcon: PropTypes.string.isRequired,
    rating: PropTypes.string.isRequired,
    reviews: PropTypes.string.isRequired,
  }).isRequired,
};

RatingDetails.defaultProps = {
  value: '0',
  reviewsCount: 0,
  renderCount: null,
};

export default withStyles(styles)(RatingDetails);
