function rad(deg) {
  return deg * (Math.PI / 180);
}

function getDistanceMeters(point1, point2) {
  const lat1 = point1.latitude;
  const lat2 = point2.latitude;
  const lng1 = point1.longitude;
  const lng2 = point2.longitude;

  const R = 6371000; // Earth’s mean radius in meter

  const dLat = rad(lat2 - lat1);
  const dLong = rad(lng2 - lng1);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(rad(lat1))
    * Math.cos(rad(lat2))
    * Math.sin(dLong / 2)
    * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

// eslint-disable-next-line import/prefer-default-export
export { getDistanceMeters };
