import PropTypes from 'prop-types';
import React from 'react';

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  label: {
    marginBottom: '4px',
  },
};

const InputContainer = ({ label, helperText, focused, children, classes, ...rest }) => (
  <FormControl
    fullWidth
    focused={focused}
    style={{ display: 'flex', flexDirection: 'column' }}
    {...rest}
  >
    {label && (
      <InputLabel shrink={false} className={classes.label}>
        {label}
      </InputLabel>
    )}
    {children}
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
);

InputContainer.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  focused: PropTypes.bool,
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

InputContainer.defaultProps = {
  label: '',
  helperText: '',
  focused: false,
};

export default withStyles(styles)(InputContainer);
