import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import { Backdrop as MuiBackdrop } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LoaderIcon from '../images/evee-loader.svg';
import styles from './styles';

const Backdrop = ({ classes, loading, transparent }) => (
  <MuiBackdrop
    className={clsx(classes.backdrop, {
      [classes.transparentBackdrop]: transparent,
    })}
    open={loading}
  >
    <img alt="" src={LoaderIcon} className={classes.svg} />
  </MuiBackdrop>
);

Backdrop.propTypes = {
  classes: PropTypes.shape({
    backdrop: PropTypes.string.isRequired,
    transparentBackdrop: PropTypes.string.isRequired,
    svg: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  transparent: PropTypes.bool,
};

Backdrop.defaultProps = {
  loading: false,
  transparent: true,
};

export default withStyles(styles)(Backdrop);
