export const AUSTRALIA_SEARCH_LOCATION = 'Australia';
export const NEW_ZEALAND_SEARCH_LOCATION = 'New Zealand';
export const DEFAULT_LOCATION_NAME = 'Map location';
export const CURRENT_LOCATION_NAME = 'Current location';

export enum LocationType {
  airport = 'airport',
  delivery = 'delivery',
  searchHistory = 'searchHistory',
  country = 'country',
  city = 'city',
  suburb = 'suburb',
  current = 'current',
}
