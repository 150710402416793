import { basket as basketModel } from '@evee/evee-ui.models';
import { formatDateApiUtc } from '@evee/evee-ui.utils';

import makeRequest from '../utils/makeRequest';

const baseBasketUrl = '/baskets';

export const createBasket = async ({ delivery, from, to, vehicleId }, currency) =>
  makeRequest({
    method: 'post',
    url: `${baseBasketUrl}`,
    body: {
      delivery,
      from: formatDateApiUtc(from),
      to: formatDateApiUtc(to),
      vehicleId,
    },
    params: { currency },
  }).then(basketModel.parse);

export const get = async (basketId, currency) =>
  makeRequest({
    method: 'get',
    url: `${baseBasketUrl}/${basketId}`,
    params: { currency },
  }).then(basketModel.parse);

export const applyDiscountCode = async (basketId, code, currency) =>
  makeRequest({
    method: 'put',
    params: { currency },
    url: `${baseBasketUrl}/${basketId}/discountCode/${code}`,
  }).then(basketModel.parse);

export const cancelDiscountCode = async (basketId, currency) =>
  makeRequest({
    method: 'delete',
    params: { currency },
    url: `${baseBasketUrl}/${basketId}/discountCode`,
  }).then(basketModel.parse);

export const applyCredit = async (basketId, amount, currency) =>
  makeRequest({
    method: 'put',
    params: { currency },
    url: `${baseBasketUrl}/${basketId}/credit/${amount}`,
  }).then(basketModel.parse);

export const cancelCredit = async (basketId, currency) =>
  makeRequest({
    method: 'delete',
    params: { currency },
    url: `${baseBasketUrl}/${basketId}/credit`,
  }).then(basketModel.parse);

export const update = async (basketId, basket) =>
  makeRequest({
    method: 'put',
    body: {
      ...basket,
    },
    url: `${baseBasketUrl}/${basketId}`,
  }).then(basketModel.parse);

export const createExtra = async (basketId, currency, extra) =>
  makeRequest({
    method: 'put',
    params: { currency },
    url: `${baseBasketUrl}/${basketId}/extras/${extra.type}`,
  });

export const removeExtra = async (basketId, currency, extra) =>
  makeRequest({
    method: 'delete',
    params: { currency },
    url: `${baseBasketUrl}/${basketId}/extras/${extra.type}`,
  });
