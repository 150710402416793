import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormControl, FormHelperText, InputAdornment, InputLabel } from '@material-ui/core';
import EveeOutlinedInput from './EveeOutlinedInput';
import getFormatError from './getFormatError';

const TextInput = ({
  value,
  disabled,
  className,
  inputClassName,
  style,
  error,
  helperText,
  type,
  format,
  onFormatError,
  name,
  onKeyDown,
  onEnter,
  label,
  multiline,
  startIcon,
  endIcon,
  required,
  fullWidth,
  capitalize,
  InputProps,
  InputLabelProps,
  onClick,
  onChange,
  onFocus,
  onBlur,
}) => {
  const [formatError, setFormatError] = useState(null);

  const keyDownHandler = (event) => {
    onKeyDown(event);

    if (event.keyCode === 13) {
      onEnter();
    }
  };

  return (
    <FormControl
      className={className}
      error={error}
      required={required}
      fullWidth={fullWidth}
      style={{ ...style, display: 'flex', flexDirection: 'column' }}
    >
      {label && (
        <InputLabel
          variant="standard"
          shrink={false}
          style={{ marginBottom: '4px' }}
          {...InputLabelProps}
        >
          {label}
        </InputLabel>
      )}

      <EveeOutlinedInput
        value={value}
        name={name}
        disabled={disabled}
        className={inputClassName}
        multiline={multiline}
        type={type}
        startAdornment={startIcon && <InputAdornment position="start">{startIcon}</InputAdornment>}
        endAdornment={endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>}
        onKeyDown={keyDownHandler}
        onChange={(e) => {
          if (capitalize) {
            onChange(e.target.value.toUpperCase());
          } else {
            onChange(e.target.value);
          }
        }}
        onBlur={(e) => {
          const err = getFormatError(format, value);
          setFormatError(err);
          onFormatError(err);
          onBlur(e);
        }}
        onClick={onClick}
        onFocus={onFocus}
        {...InputProps}
      />

      {helperText && <FormHelperText>{helperText || formatError}</FormHelperText>}
    </FormControl>
  );
};

TextInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  style: PropTypes.shape({}),
  disabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  multiline: PropTypes.bool,
  InputProps: PropTypes.shape({}),
  InputLabelProps: PropTypes.shape({}),
  format: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  capitalize: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onFormatError: PropTypes.func,
  onKeyDown: PropTypes.func,
  onEnter: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

TextInput.defaultProps = {
  value: '',
  type: '',
  className: '',
  inputClassName: '',
  multiline: false,
  label: null,
  style: {},
  disabled: false,
  error: false,
  helperText: '',
  name: '',
  format: '',
  InputProps: {},
  InputLabelProps: {},
  required: false,
  fullWidth: true,
  startIcon: null,
  endIcon: null,
  capitalize: false,
  onClick: () => {},
  onChange: () => {},
  onFormatError: () => {},
  onKeyDown: () => {},
  onEnter: () => {},
  onFocus: () => {},
  onBlur: () => {},
};

export default TextInput;
