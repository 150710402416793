const styles = (theme) => ({
  root: {
    height: '40px',
    fontSize: '13px',
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    '&$focused': {
      borderColor: theme.palette.border.active,
    },
    '&$disabled': {
      opacity: 0.6, // todo: fix disabled state
    },
  },
  focused: {},
  disabled: {},
  error: {
    borderColor: theme.palette.error.main,
  },
  input: {
    padding: '7px 24px 7px 14px',
  },
});

export default styles;
