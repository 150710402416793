const styles = (theme) => ({
  icon: {
    marginBottom: '2px',
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(0.5),
  },
  greyIcon: {
    marginBottom: '2px',
    color: theme.palette.background.main,
    marginRight: theme.spacing(0.5),
  },
  rating: {
    fontWeight: 700,
  },
  reviews: {
    fontSize: '14px',
  },
});

export default styles;
