import * as yup from 'yup';

function notEmptyString(message) {
  return this.test(
    'isNotEmptyString',
    message,
    (value) => value?.length && value.trim().length > 0,
  );
}

yup.addMethod(yup.string, 'notEmpty', notEmptyString);

const parse = (payoutRaw) =>
  payoutRaw
    ? {
        id: payoutRaw._id,
        ...payoutRaw,
      }
    : null;

const serialize = (payout) =>
  payout
    ? {
        _id: payout.id,
        ...payout,
      }
    : null;

const requiredMsg = 'required';

const accountSchema = yup.object().shape({
  accountName: yup
    .string()
    .max(50, 'Must be at most 50 characters')
    .notEmpty('Cannot be empty')
    .required(requiredMsg),
  accountNumber: yup
    .string()
    .test('accountNumber length', 'Must be at most 18 characters', (val) => String(val).length <= 18)
    .required(requiredMsg),
  bsb: yup
    .string()
    .when('isAustralia', {
      is: true,
      then: (schema) =>
        schema
          .test('routingNumber length', 'Must be at most 7 characters', (val) => String(val).length <= 7)
          .required(requiredMsg),
      otherwise: (schema) => schema.notRequired(),
    }),
});

const businessStripeAccountSchema = yup.object().shape({
  businessNumber: yup.string().required(requiredMsg),
  businessName: yup.string().required(requiredMsg),
});

export default {
  parse,
  serialize,
  businessStripeAccountSchema,
  accountSchema,
};
