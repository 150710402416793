export const save = (tokenValue, expirationDate) => {
  localStorage.setItem('evs.token', tokenValue);
  localStorage.setItem('evs.token.expiration', expirationDate);
};

export const get = () => {
  const expString = localStorage.getItem('evs.token.expiration');
  const valueString = localStorage.getItem('evs.token');

  return {
    // Temporary workaround to integrate with old version of UI
    value: valueString ? valueString.replace(/["]/g, '') : null,
    expiration: expString ? parseInt(expString, 10) : null,
  };
};

export const remove = () => {
  localStorage.removeItem('evs.token');
  localStorage.removeItem('evs.token.expiration');
};
