import CarsIcon from '../Icons/cars.svg';

import { getApis } from './core';

export const MAP_STYLES = [
  { featureType: 'all', elementType: 'labels.text.fill', stylers: [{ color: '#4e5c6a' }] },
  {
    featureType: 'administrative.locality',
    elementType: 'all',
    stylers: [{ saturation: 7 }, { lightness: 19 }, { visibility: 'on' }],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      { saturation: -100 },
      { lightness: 100 },
      { visibility: 'simplified' },
      { color: '#f6f6f7' },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [{ saturation: -100 }, { lightness: 100 }, { visibility: 'off' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      { hue: '#bbc0c4' },
      { saturation: -93 },
      { lightness: 31 },
      { visibility: 'simplified' },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [{ hue: '#bbc0c4' }, { saturation: -93 }, { lightness: 31 }, { visibility: 'on' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels',
    stylers: [
      { hue: '#bbc0c4' },
      { saturation: -93 },
      { lightness: -2 },
      { visibility: 'simplified' },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      { hue: '#e9ebed' },
      { saturation: -90 },
      { lightness: -8 },
      { visibility: 'simplified' },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [{ hue: '#e9ebed' }, { saturation: 10 }, { lightness: 69 }, { visibility: 'on' }],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      { saturation: -78 },
      { lightness: 67 },
      { visibility: 'simplified' },
      { color: '#e4e6e8' },
    ],
  },
];

const DEFAULT_CENTER = {
  lat: -25.274398,
  lng: 133.775136,
};

const CIRCLE_OPTIONS = {
  strokeColor: '#ffae6e',
  strokeWeight: 1,
  fillColor: '#ffae6e',
  fillOpacity: 0.2,
};

const getCarIcon = () => {
  const { mapsApi } = getApis();
  const iconSize = 24;

  return {
    url: CarsIcon,
    size: new mapsApi.Size(iconSize, iconSize),
    origin: new mapsApi.Point(0, 0),
    anchor: new mapsApi.Point(iconSize / 2, iconSize / 2),
  };
};
const getMapOptions = () => ({
  zoom: 3,
  gestureHandling: 'greedy',
  streetViewControl: false,
  mapTypeControl: false,
  mapTypeControlOptions: {
    mapTypeIds: [window.google.maps.MapTypeId.ROADMAP, 'map_style'],
  },
});

export const getStyledMap = (mapsApi) =>
  new mapsApi.StyledMapType(MAP_STYLES, { name: 'evee Map' });

export const createMap = (element, options = {}) => {
  const { mapsApi } = getApis();
  if (!mapsApi) {
    return null;
  }

  const map = new mapsApi.Map(element, {
    ...getMapOptions(),
    center: DEFAULT_CENTER,
    ...options,
  });
  map.mapTypes.set('map_style', getStyledMap(mapsApi));
  map.setMapTypeId('map_style');

  return map;
};

let carMarker;
let circle;

export const drawVehicle = (map, coords, radiusMeters) => {
  const { mapsApi } = getApis();
  if (!mapsApi) {
    return null;
  }

  if (carMarker) carMarker.setMap(null);
  if (circle) circle.setMap(null);

  if (radiusMeters > 0) {
    circle = new mapsApi.Circle({
      ...CIRCLE_OPTIONS,
      center: coords,
      map,
      radius: radiusMeters,
    });

    map.fitBounds(circle.getBounds());
  }

  carMarker = new mapsApi.Marker({
    position: coords,
    map,
    draggable: false,
    icon: getCarIcon(),
  });

  return { carMarker, circle };
};
