import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import { CircularProgress } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const Button = React.forwardRef(
  ({ text, type, color, size, loading, className, classes, ...rest }, ref) => (
    <MuiButton
      ref={ref}
      variant={type}
      size={size}
      color={color}
      className={clsx(classes.button, className)}
      classes={{ ...classes, button: undefined, progress: undefined }}
      {...rest}
    >
      {loading && <CircularProgress size={24} className={classes.progress} />}
      {text}
    </MuiButton>
  ),
);

Button.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    progress: PropTypes.string.isRequired,
    root: PropTypes.string,
    startIcon: PropTypes.string,
  }).isRequired,
};

Button.defaultProps = {
  text: '',
  className: '',
  type: 'contained',
  color: 'secondary',
  size: 'medium',
  loading: false,
};

export default withStyles(styles)(Button);
