import PropTypes from 'prop-types';
import React from 'react';
import { useState } from 'react';

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Autocomplete from '../EveeAutocomplete';
import EveeTextField from '../EveeTextField';
import styles from './styles';

const AsyncAutocomplete = ({
  value,
  displayField,
  renderOption,
  renderInput,
  defaultOptions,
  label,
  disabled,
  noOptionsText,
  TextFieldProps,
  InputLabelProps,
  captionText,
  error,
  required,
  helperText,
  requestMethod,
  parseResult,
  limit,
  classes,
  onChange,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const displayOptions = inputValue && options?.length ? options : defaultOptions;

  const renderDefaultInputJsx = (params) => (
    <EveeTextField
      {...params}
      fullWidth
      error={error}
      variant="outlined"
      InputProps={{
        ...params.InputProps,
        className: classes.input,
      }}
      {...TextFieldProps}
    />
  );

  const retrieveOptions = async (searchString) => {
    if (!requestMethod) {
      return;
    }

    const result = parseResult(await requestMethod(searchString));

    setOptions(result ? result.slice(0, limit) : []);
  };

  const onInputChange = async (event) => {
    const valueString = event ? event.target.value : null;

    setInputValue(valueString);
    await retrieveOptions(valueString);
  };

  return (
    <FormControl
      required={required}
      error={error}
      focused={focus}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {label && (
        <InputLabel
          variant="standard"
          shrink={false}
          style={{ marginBottom: '4px' }}
          {...InputLabelProps}
        >
          {label}
        </InputLabel>
      )}

      <Autocomplete
        autoComplete
        includeInputInList
        filterSelectedOptions
        disableClearable
        value={value}
        disabled={disabled}
        forcePopupIcon={false}
        options={displayOptions}
        renderOption={renderOption}
        noOptionsText={inputValue ? noOptionsText : captionText}
        getOptionSelected={(option, val) => option[displayField] === val[displayField]}
        getOptionLabel={(option) => option[displayField] || ''}
        renderInput={renderInput || renderDefaultInputJsx}
        classes={{
          popper: classes.popper,
          paper: classes.paper,
          option: classes.option,
          listbox: classes.listbox,
        }}
        onInputChange={onInputChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onChange={(_event, newValue) => onChange(newValue)}
        {...rest}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

AsyncAutocomplete.propTypes = {
  displayField: PropTypes.string.isRequired,
  defaultOptions: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.shape({}),
  noOptionsText: PropTypes.string,
  captionText: PropTypes.string,
  requestMethod: PropTypes.func,
  parseResult: PropTypes.func,
  renderOption: PropTypes.func,
  renderInput: PropTypes.func,
  limit: PropTypes.number,
  label: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  TextFieldProps: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  InputLabelProps: PropTypes.shape({}),
  classes: PropTypes.shape({
    input: PropTypes.string,
    paper: PropTypes.string,
    option: PropTypes.string,
    popper: PropTypes.string,
    listbox: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func,
};

AsyncAutocomplete.defaultProps = {
  noOptionsText: '',
  captionText: '',
  value: {},
  defaultOptions: [],
  requestMethod: () => {},
  parseResult: (r) => r,
  renderOption: null,
  renderInput: null,
  label: null,
  limit: 5,
  disabled: false,
  error: false,
  required: false,
  helperText: '',
  TextFieldProps: {
    placeholder: '',
  },
  InputLabelProps: {},
  onChange: () => {},
};

export default withStyles(styles)(AsyncAutocomplete);
