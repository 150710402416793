const styles = (theme) => ({
  input: {
    height: '40px',
    lineHeight: '30px',
    fontSize: '14px',
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  paper: {},
  option: {},
  popper: {},
  listbox: {},
});

export default styles;
