import { lighten } from '@material-ui/core/styles';

const styles = (theme) => ({
  button: {
    textDecoration: 'underline',
    textDecorationColor: lighten(theme.palette.primary.main, 0.6),
    textUnderlineOffset: '4px',
  },
  disabled: {
    color: lighten(theme.palette.primary.main, 0.5),
  },
});

export default styles;
