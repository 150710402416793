import { loadScript } from '@evee/evee-ui.utils';

const FACEBOOK_SCRIPT_SRC = 'https://connect.facebook.net/en_US/sdk.js';
const FACEBOOK_SCRIPT_ID = 'fb';

const isInitialized = false;

export const init = async () => {
  if (isInitialized || !process.env?.REACT_APP_FACEBOOK_APP_ID?.length) {
    return;
  }

  await loadScript(FACEBOOK_SCRIPT_SRC, FACEBOOK_SCRIPT_ID);
  window.FB.init({
    appId: process.env.REACT_APP_FACEBOOK_APP_ID,
    cookie: true,
    xfbml: true,
    version: 'v8.0',
  });
};

const signIn = async () => {
  await init();

  return new Promise((resolve, reject) => {
    window.FB.login((response) => {
      if (response.authResponse) {
        resolve(response);
      } else {
        reject(new Error('Facebook login failed'));
      }
    }, { scope: 'email' });
  });
};

export default { signIn };
