import * as yup from 'yup';
import yupPassword from 'yup-password';

function notEmptyString(message) {
  return this.test(
    'isNotEmptyString',
    message,
    (value) => value?.length && value.trim().length > 0,
  );
}

yup.addMethod(yup.string, 'notEmpty', notEmptyString);
yupPassword(yup);

const requiredMsg = 'required';

const signUpSchema = yup.object().shape({
  firstName: yup.string().notEmpty('Cannot be empty').required(requiredMsg),
  lastName: yup.string().notEmpty('Cannot be empty').required(requiredMsg),
  email: yup.string().email('Please use correct e-mail address').required(requiredMsg),
  password: yup.string().password().minSymbols(0).minRepeating(2).required(requiredMsg),
});

const updatePasswordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .min(1)
    .test(
      'isNotEmptyString',
      requiredMsg,
      (value) => value?.length && value.trim().length > 0,
    )
    .required(requiredMsg)
    .nullable(),
  password: yup
    .string()
    .password()
    .minSymbols(0)
    .minRepeating(2)
    .when('currentPassword', {
      is: (value) => value?.length > 0,
      then: (schema) =>
        schema.test(
          'cannotReuseOldPassword',
          'You cannot reuse old password',
          (value, context) => value !== context.parent.currentPassword,
        ),
      otherwise: (schema) =>
        schema.test(
          'cannotReuseOldPassword',
          'You cannot reuse old password',
          (value, context) => value !== context.options.currentPassword,
        ),
    })
    .required(requiredMsg)
    .nullable(),
  confirmPassword: yup.string().when('password', {
    is: (value) => value?.length > 0,
    then: (schema) =>
      schema.test(
        'passwordsAreEqual',
        'Passwords do not match',
        (value, context) => value === context.parent.password,
      ),
    otherwise: (schema) =>
      schema.test(
        'passwordsAreEqual',
        'Passwords do not match',
        (value, context) => value === context.options.password,
      ),
  }),
});

const resetPasswordSchema = updatePasswordSchema.pick([
  'password',
  'confirmPassword',
]);

export default {
  signUpSchema,
  resetPasswordSchema,
  updatePasswordSchema,
};
