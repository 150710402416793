import errorService from '../../errorService';
import makeRequest from '../utils/makeRequest';

const phoneUrl = '/phoneverifications';

export const sendCode = async ({ code, number }) =>
  makeRequest({
    method: 'post',
    url: phoneUrl,
    body: { phone: { code, number } },
    errorWrapper: errorService.phone.saveErr,
  });

export const checkCode = async ({ phoneNumber, verificationCode }) =>
  makeRequest({
    method: 'put',
    url: phoneUrl,
    body: { code: verificationCode, phone: phoneNumber },
    errorWrapper: errorService.phone.checkErr,
  });
