import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    height: 4,
  },
  thumb: {
    height: 20,
    width: 20,
    marginTop: -8,
    marginLeft: -10,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  mark: {},
  markActive: {},
  active: {},
  valueLabel: {},
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
});

export default withStyles(styles)(Slider);
