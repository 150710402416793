import makeRequest from '../utils/makeRequest';

const baseExtrasUrl = '/extras';

export const getVehicleExtras = async ({ vehicleId, from, to, currency }) =>
  makeRequest({
    method: 'get',
    url: `${baseExtrasUrl}/vehicle/${vehicleId}`,
    params: { from, to, currency },
  });
