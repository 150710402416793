import * as yup from 'yup';

const requiredMsg = 'required';

const profileSchema = yup.object().shape({
  firstName: yup.string().required(requiredMsg),
  lastName: yup.string().required(requiredMsg),
  dateOfBirth: yup.date().nullable(),
});

const questionnaireItemSchema = yup
  .object()
  .shape({ question: yup.string(), answer: yup.string() });

const questionnaireSchema = yup.object().shape({
  questionnaire: yup
    .array()
    .of(questionnaireItemSchema)
    .test('all questions answered', 'select all answers', (v) => v.every((e) => e.answer !== '')),
});

export default {
  profileSchema,
  questionnaireSchema,
};
