import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const getExpirationDate = () => {
  const current = new Date();
  const nextYear = new Date();
  nextYear.setFullYear(current.getFullYear() + 1);

  return nextYear;
};

const acceptCookies = () =>
  cookies.set('evs.cookiesAccepted', true, { path: '/', expires: getExpirationDate() });

const isCookiesAccepted = () => cookies.get('evs.cookiesAccepted') === 'true';

const setBasket = (bookingId) => {
  cookies.set('evs.basket', bookingId, { path: '/' });
};

export default {
  acceptCookies,
  isCookiesAccepted,
  setBasket,
};
