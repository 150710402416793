import { createErrorWrapper } from '../utils';

export const LOADING_ERROR = 'EXTRA_PAYMENTS.LOADING_ERROR';
export const LOADING_DRAFT_ERROR = 'EXTRA_PAYMENTS.LOADING_DRAFT_ERROR';
export const CREATE_ERROR = 'EXTRA_PAYMENTS.CREATE_ERROR';
export const UPLOAD_FILE_ERROR = 'EXTRA_PAYMENTS.UPLOAD_FILE_ERROR';
export const DELETE_FILE_ERROR = 'EXTRA_PAYMENTS.DELETE_FILE_ERROR';
export const DELETE_DRAFT_ERROR = 'EXTRA_PAYMENTS.DELETE_DRAFT_ERROR';
export const CANCEL_ERROR = 'EXTRA_PAYMENTS.CANCEL_ERROR';
export const DECLINE_ERROR = 'EXTRA_PAYMENTS.DECLINE_ERROR';
export const ACCEPT_ERROR = 'EXTRA_PAYMENTS.ACCEPT_ERROR';

export const loadingErr = createErrorWrapper(LOADING_ERROR, 'Failed to load extra payments');
export const loadingDraftErr = createErrorWrapper(LOADING_DRAFT_ERROR, 'Failed to load draft');
export const createErr = createErrorWrapper(CREATE_ERROR, 'Failed to create extra payments');
export const uploadErr = createErrorWrapper(UPLOAD_FILE_ERROR, 'Failed to upload file');
export const deleteErr = createErrorWrapper(DELETE_FILE_ERROR, 'Failed to delete file');
export const deleteDraftErr = createErrorWrapper(DELETE_DRAFT_ERROR, 'Failed to delete draft');
export const cancelErr = createErrorWrapper(CANCEL_ERROR, 'Failed to cancel extra payment');
export const declineErr = createErrorWrapper(DECLINE_ERROR, 'Failed to decline extra payment');
export const acceptErr = createErrorWrapper(ACCEPT_ERROR, 'Failed to accept extra payment');
