const styles = (theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.secondary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.border.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.border.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.border.main,
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
        borderColor: theme.palette.border.active,
      },
    },
  },
});

export default styles;
