import { createErrorWrapper } from '../utils';

export const LOADING_ERROR = 'PAYOUT.LOADING_ERROR';
export const UPDATE_ERROR = 'PAYOUT.UPDATE_ERROR';
export const CREATE_ERROR = 'PAYOUT.CREATE_ERROR';
export const DELETE_ERROR = 'PAYOUT.DELETE_ERROR';

export const loadingErr = createErrorWrapper(LOADING_ERROR, 'Failed to load payouts');
export const updateErr = createErrorWrapper(LOADING_ERROR, 'Failed to update payout');
export const createErr = createErrorWrapper(LOADING_ERROR, 'Failed to create payout');
export const deleteErr = createErrorWrapper(LOADING_ERROR, 'Failed to delete payout');
