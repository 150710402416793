import * as basket from './modules/basket';
import * as blog from './modules/blog';
import * as booking from './modules/booking';
import * as changeRequest from './modules/changeRequest';
import * as chat from './modules/chat';
import * as customer from './modules/customer';
import * as dictionary from './modules/dictionary';
import * as extraPayments from './modules/extraPayments';
import * as extras from './modules/extras';
import * as licence from './modules/licence';
import * as listing from './modules/listing';
import * as location from './modules/location';
import * as notification from './modules/notification';
import * as partner from './modules/partner';
import * as paymentMethod from './modules/paymentMethod';
import * as payout from './modules/payout';
import * as phone from './modules/phone';
import * as pushSubscription from './modules/pushSubscription';
import * as review from './modules/review';
import * as search from './modules/search';
import * as staticPage from './modules/staticPage';
import * as vehicle from './modules/vehicle';

export default {
  basket,
  blog,
  booking,
  changeRequest,
  chat,
  customer,
  dictionary,
  extraPayments,
  extras,
  licence,
  listing,
  location,
  notification,
  partner,
  paymentMethod,
  payout,
  phone,
  pushSubscription,
  review,
  search,
  staticPage,
  vehicle,
};
