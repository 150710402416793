import makeRequest from '../utils/makeRequest';

const pushSubscriptionsUrl = '/pushSubscriptions';

export const subscriptionConnected = async (endpoint) =>
  makeRequest({
    method: 'post',
    body: { endpoint },
    url: `${pushSubscriptionsUrl}/connected`,
  });

export const disconnectSubscription = async (endpoint) =>
  makeRequest({
    method: 'delete',
    body: { endpoint },
    url: `${pushSubscriptionsUrl}/disconnect`,
  });

export const saveSubscription = async (subscription) =>
  makeRequest({
    method: 'post',
    url: pushSubscriptionsUrl,
    body: { subscription: JSON.stringify(subscription) },
  });

export const sendPush = async (data) =>
  makeRequest({
    method: 'post',
    url: `${pushSubscriptionsUrl}/push`,
    body: { data: JSON.stringify(data) },
  });
