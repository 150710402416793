import PropTypes from 'prop-types';
import React from 'react';

import { Box, Collapse } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '../../buttons/IconButton';
import styles from './styles';

const PersistentNotification = ({ open, titleIcon, children, classes, onClose, ...rest }) => (
  <Collapse in={open}>
    <Box className={classes.container} {...rest}>
      <Box display="flex" justifyContent="space-between" alignItems="center" paddingBottom="16px">
        {titleIcon}
        <IconButton icon={<CloseOutlinedIcon />} onClick={onClose} />
      </Box>
      {children}
    </Box>
  </Collapse>
);

PersistentNotification.propTypes = {
  open: PropTypes.bool,
  titleIcon: PropTypes.node,
  children: PropTypes.node,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
};

PersistentNotification.defaultProps = {
  open: false,
  titleIcon: null,
  children: null,
  onClose: () => {},
};

export default withStyles(styles)(PersistentNotification);
