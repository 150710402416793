import { lighten } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    textDecorationColor: lighten(theme.palette.primary.main, 0.6),
    textUnderlineOffset: '4px',
    fontWeight: 'bold',
  },
});

export default styles;
