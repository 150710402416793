const getAddressFieldValue = (address, fieldName, longValue = false) => {
  const component = address.find((a) => a.types.includes(fieldName));
  if (!component) {
    return '';
  }

  return longValue ? component.long_name : component.short_name;
};

const getStreet = (address) => {
  let subpremise = getAddressFieldValue(address, 'subpremise');
  let number = getAddressFieldValue(address, 'street_number');
  const route = getAddressFieldValue(address, 'route');

  subpremise = subpremise ? `${subpremise}/` : '';
  number = number ? `${number} ` : '';

  return subpremise + number + route;
};

const getUtcOffset = (place) => Math.floor(place.utc_offset_minutes / 60);

const parse = (googlePlace) => {
  const addressComponents = googlePlace.address_components;
  const {
    geometry: { location },
    name,
  } = googlePlace;
  if (!addressComponents) {
    return null;
  }

  return {
    // todo: `name` has already been used on the backend
    shortDescription: name,
    country: getAddressFieldValue(addressComponents, 'country', true),
    countryCode: getAddressFieldValue(addressComponents, 'country'),
    state: getAddressFieldValue(addressComponents, 'administrative_area_level_1'),
    suburb: getAddressFieldValue(addressComponents, 'locality'),
    postcode: getAddressFieldValue(addressComponents, 'postal_code'),
    utcOffset: getUtcOffset(googlePlace),
    street: getStreet(addressComponents),
    latitude: location.lat(),
    longitude: location.lng(),
  };
};

const serialize = (place) => ({
  ...place,
});

export default {
  parse,
  serialize,
};
