const styles = (theme) => ({
  scrollableWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    overflowY: 'hidden',
    marginBottom: theme.spacing(2),
    width: '100%',
    '-webkit-overflow-scrolling': 'touch',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export default styles;
