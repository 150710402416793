import { review as reviewModel } from '@evee/evee-ui.models';

import errorService from '../../errorService';
import makeRequest from '../utils/makeRequest';

const baseReviewsUrl = '/reviews';
const companyReviewsUrl = '/companyReviews';

export const getFeatured = async () =>
  makeRequest({
    method: 'get',
    url: `${companyReviewsUrl}`,
    errorWrapper: errorService.vehicle.loadingErr,
  }).then((r) => r.map(reviewModel.parse));

export const create = async () =>
  makeRequest({
    method: 'post',
    url: `${baseReviewsUrl}/featured`,
    errorWrapper: errorService.vehicle.loadingErr,
  }).then(reviewModel.parse);
