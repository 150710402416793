import { alpha, darken, lighten } from '@material-ui/core/styles';

const styles = (theme) => ({
  button: {
    fontSize: '14px',
    height: '40px',
    fontWeight: 700,
    padding: theme.spacing(1, 2),
    textTransform: 'none',
  },

  containedPrimary: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.22),
    },
  },

  containedSecondary: {
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: darken(theme.palette.secondary.main, 0.08),
    },
  },

  outlinedPrimary: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
  },

  outlinedSecondary: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: darken(theme.palette.secondary.main, 0.08),
    },
  },

  containedSizeSmall: {
    fontSize: '13px',
    height: '32px',
    padding: '5px 12px',
  },

  containedSizeLarge: {
    fontSize: '15px',
    height: '48px',
    padding: '11px 24px',
  },

  textSizeSmall: {
    fontSize: '13px',
    height: '32px',
    padding: '5px 12px',
  },

  textSizeLarge: {
    fontSize: '15px',
    height: '48px',
    padding: '11px 24px',
  },

  outlinedSizeSmall: {
    fontSize: '13px',
    height: '32px',
    padding: '5px 12px',
  },

  outlinedSizeLarge: {
    fontSize: '15px',
    height: '48px',
    padding: '11px 24px',
  },

  progress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  },

  // to hide material-ui warnings
  root: {},
  startIcon: {},
});

export default styles;
