import { paymentMethod as paymentMethodModel } from '@evee/evee-ui.models';

import errorService from '../../errorService';
import makeRequest from '../utils/makeRequest';

const paymentMethodsUrl = 'customers/me/paymentMethods';

const getAll = async () =>
  makeRequest({
    method: 'get',
    url: `${paymentMethodsUrl}`,
    errorWrapper: errorService.paymentMethod.loadingErr,
  }).then((r) => r.map(paymentMethodModel.parse));

const create = async (token, billingAddress, currency) =>
  makeRequest({
    method: 'post',
    url: `${paymentMethodsUrl}`,
    params: { currency },
    body: { stripeToken: token, billingAddress },
    errorWrapper: errorService.paymentMethod.createErr,
  }).then(paymentMethodModel.parseCreate);

const setDefault = async (id) =>
  makeRequest({
    method: 'post',
    url: `${paymentMethodsUrl}/${id}/default`,
    errorWrapper: errorService.paymentMethod.updateErr,
  }).then(paymentMethodModel.parse);

const remove = async (id) =>
  makeRequest({
    method: 'delete',
    url: `${paymentMethodsUrl}/${id}`,
    errorWrapper: errorService.paymentMethod.deleteErr,
  }).then(paymentMethodModel.parse);

const verify = async (id) =>
  makeRequest({
    method: 'post',
    url: `${paymentMethodsUrl}/${id}/verify`,
    errorWrapper: errorService.paymentMethod.updateErr,
  }).then(paymentMethodModel.parse);

export { getAll, create, setDefault, remove, verify };
