import errorService from '../../errorService';
import makeRequest from '../utils/makeRequest';

const baseBlogUrl = '/blog';

export const getFeatured = async () =>
  makeRequest({
    method: 'get',
    url: `${baseBlogUrl}/posts/featured`,
    params: { take: 12 },
    errorWrapper: errorService.blog.loadErr,
  });
