import axios from 'axios';

import { createErrorWrapper } from '../../errorService/utils';
import storage from '../../storage';

const baseApiUrl = process.env.REACT_APP_API;

let axiosInstance;

export default async (config) => {
  if (!axiosInstance) {
    axiosInstance = axios.create({
      baseURL: baseApiUrl,
      timeout: 30000,
    });
  }

  const headers = {
    ...config.headers,
  };

  const token = storage.token.get().value;
  if (token) {
    headers['x-access-token'] = token;
  }

  const { method = 'get' } = config;

  try {
    const response =
      method === 'get' || method === 'delete'
        ? await axiosInstance[method](config.url, {
            headers,
            params: config.params,
            data: config.body,
          })
        : await axiosInstance[method](config.url, config.body, {
            headers,
            params: config.params,
          });

    return response.data;
  } catch (err) {
    if (config.errorWrapper) {
      throw config.errorWrapper(err);
    } else {
      throw createErrorWrapper(err?.request?.status)(err);
    }
  }
};
