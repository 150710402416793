import errorService from '../../errorService';
import makeRequest from '../utils/makeRequest';

const notificationsUrl = '/notifications';

export const getCount = async () =>
  makeRequest({
    method: 'get',
    url: `${notificationsUrl}`,
    errorWrapper: errorService.notification.getErr,
  });

export const remove = async (id) =>
  makeRequest({
    method: 'delete',
    url: `${notificationsUrl}/${id}`,
    errorWrapper: errorService.notification.deleteErr,
  });

export const removeMany = async (ids) =>
  makeRequest({
    method: 'delete',
    url: notificationsUrl,
    body: {
      ids,
    },
    errorWrapper: errorService.notification.deleteErr,
  });

export default {
  getCount,
  remove,
  removeMany,
};
