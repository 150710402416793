import React, { useState } from 'react';

import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import IconButton from '../../buttons/IconButton';
import TextInput from '../TextInput';

const PasswordInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordJsx = (
    <IconButton
      disableRipple
      icon={showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
      style={{ padding: 0 }}
      onClick={() => setShowPassword((value) => !value)}
    />
  );

  return (
    <TextInput {...props} type={showPassword ? 'text' : 'password'} endIcon={showPasswordJsx} />
  );
};

PasswordInput.propTypes = {};
PasswordInput.defaultProps = {};

export default PasswordInput;
