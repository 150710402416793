const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  menu: {
    maxHeight: '350px', // Limit the default height because some lists can have many elements. For example, a time selector.
    zIndex: '9999 !important',
  },
  icon: {
    fill: theme.palette.text.primary,
  },
});

export default styles;
