import moment from 'moment';

export const parse = (customerRaw) => {
  if (!customerRaw) {
    return null;
  }

  return {
    id: customerRaw._id,
    ...customerRaw,
  };
};

const serialize = (customerRaw) => ({
  ...customerRaw,
  dateOfBirth: customerRaw.dateOfBirth
    ? moment(customerRaw.dateOfBirth).utc(true).format('YYYY-MM-DD')
    : null,
});

export default {
  parse,
  serialize,
};
