import FKGroteskNeueBlackWoff from './FKGroteskNeue-Black.woff';
import FKGroteskNeueBoldWoff from './FKGroteskNeue-Bold.woff';
import FKGroteskNeueLightWoff from './FKGroteskNeue-Light.woff';
import FKGroteskNeueMediumWoff from './FKGroteskNeue-Medium.woff';
import FKGroteskNeueRegularWoff from './FKGroteskNeue-Regular.woff';

import FKGroteskNeueBlackWoff2 from './FKGroteskNeue-Black.woff2';
import FKGroteskNeueBoldWoff2 from './FKGroteskNeue-Bold.woff2';
import FKGroteskNeueLightWoff2 from './FKGroteskNeue-Light.woff2';
import FKGroteskNeueMediumWoff2 from './FKGroteskNeue-Medium.woff2';
import FKGroteskNeueRegularWoff2 from './FKGroteskNeue-Regular.woff2';

const fKGroteskNeueLocal = [
  {
    fontFamily: 'FKGroteskNeue',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 100,
    src: `
      url(${FKGroteskNeueLightWoff}) format('woff'),
      url(${FKGroteskNeueLightWoff2}) format('woff2')
    `,
  },
  {
    fontFamily: 'FKGroteskNeue',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      url(${FKGroteskNeueRegularWoff}) format('woff'),
      url(${FKGroteskNeueRegularWoff2}) format('woff2')
    `,
  },
  {
    fontFamily: 'FKGroteskNeue',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `
      url(${FKGroteskNeueMediumWoff}) format('woff'),
      url(${FKGroteskNeueMediumWoff2}) format('woff2')
    `,
  },
  {
    fontFamily: 'FKGroteskNeue',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
      url(${FKGroteskNeueBoldWoff}) format('woff'),
      url(${FKGroteskNeueBoldWoff2}) format('woff2')
    `,
  },
  {
    fontFamily: 'FKGroteskNeue',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 900,
    src: `
      url(${FKGroteskNeueBlackWoff}) format('woff'),
      url(${FKGroteskNeueBlackWoff2}) format('woff2')
    `,
  },
];

const fKGroteskNeueRemote = [
  {
    fontFamily: 'FKGroteskNeue',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 100,
    src: `
      url('https://eveeh-live.s3.ap-southeast-2.amazonaws.com/fonts/FKGroteskNeue-Light.woff') format('woff'),
      url('https://eveeh-live.s3.ap-southeast-2.amazonaws.com/fonts/FKGroteskNeue-Light.woff2') format('woff2')
    `,
  },
  {
    fontFamily: 'FKGroteskNeue',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      url('https://eveeh-live.s3.ap-southeast-2.amazonaws.com/fonts/FKGroteskNeue-Regular.woff') format('woff'),
      url('https://eveeh-live.s3.ap-southeast-2.amazonaws.com/fonts/FKGroteskNeue-Regular.woff2') format('woff2')
    `,
  },
  {
    fontFamily: 'FKGroteskNeue',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `
      url('https://eveeh-live.s3.ap-southeast-2.amazonaws.com/fonts/FKGroteskNeue-Medium.woff') format('woff'),
      url('https://eveeh-live.s3.ap-southeast-2.amazonaws.com/fonts/FKGroteskNeue-Medium.woff2') format('woff2')
    `,
  },
  {
    fontFamily: 'FKGroteskNeue',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
      url('https://eveeh-live.s3.ap-southeast-2.amazonaws.com/fonts/FKGroteskNeue-Bold.woff') format('woff'),
      url('https://eveeh-live.s3.ap-southeast-2.amazonaws.com/fonts/FKGroteskNeue-Bold.woff2') format('woff2')
    `,
  },
  {
    fontFamily: 'FKGroteskNeue',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 900,
    src: `
      url('https://eveeh-live.s3.ap-southeast-2.amazonaws.com/fonts/fontFKGroteskNeue-Black.woff') format('woff'),
      url('https://eveeh-live.s3.ap-southeast-2.amazonaws.com/fonts/fontFKGroteskNeue-Black.woff2) format('woff2')
    `,
  },
];

export default {
  fKGroteskNeueLocal,
  fKGroteskNeueRemote,
};
