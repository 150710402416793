import { account as accountModel, customer as customerModel } from '@evee/evee-ui.models';

import errorService from '../../errorService';
import facebook from '../../facebook';
import google from '../../google';
import makeRequest from '../utils/makeRequest';
import socket from '../../socket';
import storage from '../../storage';

const BASE_CUSTOMER_URL = 'customers';

const saveUser = (userResponse) => {
  storage.token.save(userResponse.token, userResponse.expires);
  storage.user.save(userResponse.user);
};

const signIn = async (credentials) => {
  const result = await makeRequest({
    method: 'post',
    url: `${BASE_CUSTOMER_URL}/signin`,
    body: credentials,
    errorWrapper: errorService.auth.authErr,
  });

  saveUser(result);
  socket.sendCustomerId(result.user.id);
  return result.user;
};

const googleAuth = async (url) => {
  const googleToken = await google.auth.signIn();

  const result = await makeRequest({
    method: 'post',
    url,
    body: { googleToken },
    errorWrapper: errorService.auth.authErr,
  });

  saveUser(result);
  socket.sendCustomerId(result.user.id);
  return result.user;
};

const googleSignIn = () => googleAuth(`${BASE_CUSTOMER_URL}/googleSignIn`);

const linkGoogleAccount = () => googleAuth(`${BASE_CUSTOMER_URL}/googleConnect`);

const facebookAuth = async (url) => {
  const { authResponse } = await facebook.signIn();
  if (!authResponse) {
    throw new Error('Facebook auth failed');
  }

  const result = await makeRequest({
    method: 'post',
    url,
    body: { facebookToken: authResponse.accessToken },
    errorWrapper: errorService.auth.authErr,
  });

  storage.token.save(result.token, result.expires);
  storage.user.save(result.user);
  socket.sendCustomerId(result.user.id);

  return result.user;
};

const facebookSignIn = () => facebookAuth(`${BASE_CUSTOMER_URL}/facebookSignIn`);

const linkFacebookAccount = () => facebookAuth(`${BASE_CUSTOMER_URL}/facebookConnect`);

const signUp = async (user) => {
  const registerResult = await makeRequest({
    method: 'post',
    url: BASE_CUSTOMER_URL,
    body: user,
    errorWrapper: errorService.auth.authErr,
  });

  saveUser(registerResult);
  socket.sendCustomerId(registerResult.user.id);
  return registerResult.user;
};

const forgotPassword = async (email) =>
  makeRequest({
    method: 'post',
    url: `${BASE_CUSTOMER_URL}/forgotPassword`,
    body: { email },
    errorWrapper: errorService.auth.authErr,
  });

const resetPassword = async ({ password, token }) =>
  makeRequest({
    method: 'post',
    url: `${BASE_CUSTOMER_URL}/resetPassword/${token}`,
    body: { password },
    errorWrapper: errorService.auth.authErr,
  });

const updatePassword = async (oldPassword, newPassword) =>
  makeRequest({
    method: 'post',
    url: `${BASE_CUSTOMER_URL}/me/password`,
    body: { oldPassword, newPassword },
    errorWrapper: errorService.auth.authErr,
  });

const subscribe = async (email) => {
  await makeRequest({
    method: 'post',
    url: `${BASE_CUSTOMER_URL}/subscribe`,
    body: { email },
    errorWrapper: errorService.auth.authErr,
  });

  storage.subscribe.save(true);
};

const signout = async () => {
  const user = storage.user.get();
  socket.removeCustomerId(user.id);
  storage.token.remove();
  storage.user.remove();
};

const me = async () =>
  makeRequest({
    method: 'get',
    url: `${BASE_CUSTOMER_URL}/me`,
    errorWrapper: errorService.auth.authErr,
  }).then(customerModel.parse);

const getAccountInfo = async ({ accountId }) =>
  makeRequest({
    method: 'get',
    url: `customers/${accountId}`,
    // errorWrapper: errorService.vehicle.loadingErr,
  }).then((r) => accountModel.parse(r));

const update = async (customerData) =>
  makeRequest({
    method: 'put',
    url: `${BASE_CUSTOMER_URL}/me`,
    body: customerModel.serialize(customerData),
    errorWrapper: errorService.auth.authErr,
  });

const uploadAvatar = async (photo) =>
  makeRequest({
    method: 'post',
    url: `${BASE_CUSTOMER_URL}/me/avatars`,
    body: { photo },
    errorWrapper: errorService.auth.authErr,
  });

const verifyDeclaration = async (declaration) =>
  makeRequest({
    method: 'post',
    url: `${BASE_CUSTOMER_URL}/me/declaration`,
    body: declaration,
    errorWrapper: errorService.auth.authErr,
  });

const getFeaturedHosts = async () =>
  makeRequest({
    method: 'get',
    url: `${BASE_CUSTOMER_URL}/hosts/featured`,
    params: { take: 12 },
    errorWrapper: errorService.auth.authErr,
  });

const verification = async () =>
  makeRequest({
    method: 'post',
    url: `${BASE_CUSTOMER_URL}/verification`,
    errorWrapper: errorService.auth.authErr,
  });

const deactivate = async (reason) =>
  makeRequest({
    method: 'delete',
    url: `${BASE_CUSTOMER_URL}/me`,
    body: { reason },
    errorWrapper: errorService.auth.authErr,
  });

const sendEmailVerification = async () =>
  makeRequest({
    method: 'post',
    url: `${BASE_CUSTOMER_URL}/me/resendEmailVerification`,
    errorWrapper: errorService.auth.authErr,
  });

const changeEmail = async (email) =>
  makeRequest({
    method: 'post',
    url: `${BASE_CUSTOMER_URL}/me/changeEmail`,
    body: { email },
    errorWrapper: errorService.auth.authErr,
  });

const verifyEmail = async (code) =>
  makeRequest({
    method: 'post',
    url: `${BASE_CUSTOMER_URL}/me/verifyEmail/${code}`,
    errorWrapper: errorService.auth.authErr,
  });

const reCaptchaVerify = async (token, version) =>
  makeRequest({
    method: 'post',
    url: `${BASE_CUSTOMER_URL}/reCaptchaVerify`,
    body: { token, version },
    errorWrapper: errorService.auth.authErr,
  });

const getRewards = async () =>
  makeRequest({
    method: 'get',
    url: `${BASE_CUSTOMER_URL}/me/rewards`,
  });

export {
  signIn,
  googleSignIn,
  facebookSignIn,
  linkFacebookAccount,
  linkGoogleAccount,
  signUp,
  signout,
  subscribe,
  forgotPassword,
  resetPassword,
  updatePassword,
  me,
  getAccountInfo,
  uploadAvatar,
  verifyDeclaration,
  update,
  verification,
  getFeaturedHosts,
  deactivate,
  sendEmailVerification,
  changeEmail,
  verifyEmail,
  reCaptchaVerify,
  getRewards,
};
