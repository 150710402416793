import { LocationType } from '@evee/evee-ui.enums';
import _ from 'lodash';

const parseTotals = (totalsRaw) => {
  if (!totalsRaw || _.isEmpty(totalsRaw)) {
    return null;
  }

  return {
    ...totalsRaw,
    totalRental: _.sumBy(totalsRaw.bookingDaysPrices, 'priceBeforeDiscount'),
    totalDiscount: _.sumBy(totalsRaw.bookingDaysPrices, 'discountAmount'),
  };
};

const parse = (vehicleRaw) => {
  if (!vehicleRaw || _.isEmpty(vehicleRaw)) {
    return null;
  }

  const { delivery } = vehicleRaw;

  const deliveryRadius = delivery?.radius;
  const deliveryPrice = delivery?.price;

  const deliveryAddress = delivery?.locations
    ? delivery.locations.find((l) => l.default && l.type === LocationType.delivery)
    : null;

  if (deliveryAddress) {
    deliveryAddress.description = deliveryAddress.description || deliveryAddress.name;
  }

  const deliveryAirport = delivery?.locations
    ? delivery.locations.find((l) => l.type === LocationType.airport)
    : null;

  const extraLocations = delivery?.locations
    ? delivery.locations.filter((l) => !l.default && l.type === LocationType.delivery)
    : [];

  const nameParts = [vehicleRaw.make, vehicleRaw.model];

  return {
    ...vehicleRaw,
    id: vehicleRaw._id,
    name: _.compact(nameParts).join(' '),
    deliveryAddress,
    deliveryAvailable: !!deliveryPrice,
    deliveryAirport,
    deliveryAirportPrice: deliveryAirport?.price ?? 0,
    deliveryAirportAvailable: !!deliveryAirport,
    extraLocations,
    deliveryRadius,
    deliveryPrice,
    longTripDiscountAvailable: vehicleRaw.longTripDiscountAvailable || false,
  };
};

const serialize = (vehicle) => {
  const { deliveryPrice, deliveryRadius, deliveryAddress, deliveryAirport, deliveryAirportPrice } =
    vehicle;

  const locations = [];
  if (deliveryAddress) {
    locations.push({
      ...deliveryAddress,
      id: deliveryAddress.id,
      shortDescription: undefined,
      default: true,
      type: LocationType.delivery,
      name: `${deliveryAddress.suburb}, ${deliveryAddress.state}`,
      price: 0,
    });
  }

  if (deliveryAirport) {
    locations.push({
      ...deliveryAirport,
      id: deliveryAirport.id,
      shortDescription: undefined,
      type: LocationType.airport,
      price: deliveryAirportPrice || 0,
    });
  }

  if (vehicle.extraLocations) {
    locations.push(
      ...vehicle.extraLocations.map((l) => ({
        ...l,
        id: l.id,
        shortDescription: undefined,
        name: `${l.suburb}, ${l.state}`,
        type: LocationType.delivery,
        price: l.price || 0,
      })),
    );
  }

  return {
    _id: vehicle.id,
    ...vehicle,
    delivery: {
      radius: deliveryRadius,
      price: deliveryPrice,
      locations,
    },
  };
};

export default {
  parseTotals,
  parse,
  serialize,
};
