import PropTypes from 'prop-types';
import React from 'react';

import { Box, Switch, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    height: 40,
  },
});

const CustomSwitch = ({ checked, disabled, label, onChange, classes, variant, ...rest }) => (
  <Box display="flex" alignItems="center" className={classes.container} {...rest}>
    <Switch
      color="primary"
      checked={checked}
      disabled={disabled}
      onChange={(e) => onChange(e.target.checked)}
    />
    <Typography color="primary" variant={variant} component="div">
      {label}
    </Typography>
  </Box>
);

CustomSwitch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variant: PropTypes.string,
  onChange: PropTypes.func,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
};

CustomSwitch.defaultProps = {
  checked: false,
  disabled: false,
  label: '',
  variant: 'body2',
  onChange: () => {},
};

export default withStyles(styles)(CustomSwitch);
