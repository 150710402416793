import PropTypes from 'prop-types';
import React from 'react';

import MaterialRating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  icon: {
    color: theme.palette.secondary.main,
    margin: '0 5px 0 0',
  },
});

const Rating = ({ classes, value, size, className, readOnly, onChange, ...rest }) => (
  <MaterialRating
    size={size}
    value={value}
    readOnly={readOnly}
    className={className}
    precision={1}
    icon={<StarIcon className={classes.icon} fontSize="inherit" />}
    emptyIcon={<StarBorderIcon className={classes.icon} fontSize="inherit" />}
    onChange={(event, newValue) => onChange(newValue)}
    {...rest}
  />
);

Rating.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

Rating.defaultProps = {
  readOnly: false,
  className: '',
  size: 'small',
  onChange: () => {},
};

export default withStyles(styles)(Rating);
