import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';

const Autocomplete = withStyles((theme) => ({
  paper: {},
  popper: {},
  listbox: {},
  option: {
    color: theme.palette.text.primary,
  },
}))(MuiAutocomplete);

export default Autocomplete;
