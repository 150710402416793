import PropTypes from 'prop-types';
import React from 'react';

import { Box, Link as MuiLink } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const Link = ({ color, variant, ...rest }) => {
  if (variant === 'underline') {
    return <MuiLink underline="always" color={color} {...rest} />;
  }

  if (variant === 'arrow') {
    return (
      <Box display="flex" alignItems="center">
        <MuiLink underline="none" color={color} {...rest} />
        <ChevronRight />
      </Box>
    );
  }

  return null;
};

Link.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.oneOf(['underline', 'arrow']),
};

Link.defaultProps = {
  color: 'textPrimary',
  variant: 'underline',
};

export default withStyles(styles)(Link);
