import PropTypes from 'prop-types';
import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmDialog = ({ open, contentText, onCancel, onConfirm }) => (
  <Dialog open={open} onClose={onCancel}>
    <DialogTitle>Confirm action</DialogTitle>

    <DialogContent>
      <DialogContentText>{contentText}</DialogContentText>
    </DialogContent>

    <DialogActions disableSpacing>
      <Button fullWidth onClick={onCancel}>
        Cancel
      </Button>
      <Button autoFocus fullWidth onClick={onConfirm}>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  contentText: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

ConfirmDialog.defaultProps = {
  open: false,
  contentText: '',
  onCancel: () => {},
  onConfirm: () => {},
};

export default ConfirmDialog;
