import React from 'react';
import parse from 'autosuggest-highlight/parse';

import { Box, Typography } from '@material-ui/core';

const OptionView = (option) => {
  if (!option.structured_formatting) {
    return (
      <Box>
        <Typography variant="body1" color="textPrimary" style={{ fontWeight: 400 }}>
          {option.description}
        </Typography>
        <Typography variant="body2" color="textPrimary">
          {option.secondary_text}
        </Typography>
      </Box>
    );
  }
  const matches = option.structured_formatting.main_text_matched_substrings;
  const parts = parse(
    option.structured_formatting.main_text,
    matches.map((match) => [match.offset, match.offset + match.length]),
  );

  return (
    <Box>
      {parts.map((part, index) => (
        <Typography
          key={index}
          display="inline"
          variant="body1"
          color={part.highlight ? 'secondary' : 'textPrimary'}
          style={{ fontSize: '15px', fontWeight: part.highlight ? 700 : 400 }}
        >
          {part.text}
        </Typography>
      ))}

      <Typography variant="body2" color="textSecondary">
        {option.structured_formatting.secondary_text}
      </Typography>
    </Box>
  );
};

export default OptionView;
