const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: theme.shape.borderRadiusMedium,
    marginBottom: theme.spacing(7),
  },
});

export default styles;
