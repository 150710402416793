import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const HorizontalScrollMenu = ({ children, classes, ...rest }) => (
  <Box className={classes.scrollableWrapper} {...rest}>
    {children}
  </Box>
);

HorizontalScrollMenu.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({
    scrollableWrapper: PropTypes.string.isRequired,
  }).isRequired,
};

HorizontalScrollMenu.defaultProps = {
  children: null,
};

export default withStyles(styles)(HorizontalScrollMenu);
