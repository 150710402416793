const loadScript = (src, id) =>
  new Promise((resolve, reject) => {
    try {
      const script = document.createElement('script');
      script.id = id;
      script.src = src;
      script.async = true;

      document.querySelector('head').appendChild(script);
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject(new Error(`Can't load script ${script.src}`));
      };
    } catch (err) {
      reject(err);
    }
  });

export { loadScript };
