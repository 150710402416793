import { Region } from '@evee/evee-ui.enums';
import TagManager from 'react-gtm-module';

const init = async (region) => {
  const gtmId =
    region.id === Region.australia.id
      ? process.env.REACT_APP_GTM_ID_AU
      : process.env.REACT_APP_GTM_ID_NZ;

  if (!gtmId?.length) return;

  TagManager.initialize({
    gtmId,
  });
};

export default {
  init,
};
