import errorService from '../../errorService';
import makeRequest from '../utils/makeRequest';

const licencesUrl = '/licences';

export const create = async ({ licence }) => {
  const formData = new FormData();
  formData.append('licence', new Blob([licence]));
  return makeRequest({
    method: 'post',
    url: `${licencesUrl}`,
    body: formData,
    errorWrapper: errorService.licence.uploadErr,
  });
};

export const remove = async ({ id }) =>
  makeRequest({
    method: 'delete',
    url: `${licencesUrl}/${id}`,
    errorWrapper: errorService.licence.deleteErr,
  });

export default {
  create,
  remove,
};
