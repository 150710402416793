import PropTypes from 'prop-types';
import React from 'react';

import { Alert } from '@material-ui/lab';

const ErrorAlert = ({ message }) => {
  if (!message.length) return null;

  return (
    <Alert variant="filled" severity="error">
      <span style={{ fontSize: '16px' }}>{message}</span>
    </Alert>
  );
};

ErrorAlert.propTypes = {
  message: PropTypes.string,
};
ErrorAlert.defaultProps = {
  message: '',
};

export default ErrorAlert;
