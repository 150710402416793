import moment from 'moment';

const DEFAULT_TRIP_LENGTH = 24;
const DEFAULT_VEHICLE_UTC_OFFSET = 10;
const MIN_HOURS_BETWEEN_BOOKINGS = 4;
const BOOKING_NOTICE_PERIOD = 12;

const OccupationTypes = {
  FULL_DAY: 'fullDay',
  START_OF_DAY: 'startOfDay',
  END_OF_DAY: 'endOfDay',
  DISABLED: 'disabled',
};

const getFullDayDates = (startDate, endDate) => {
  let dates = [];
  const theDate = moment(startDate);

  while (theDate < endDate) {
    dates = [...dates, moment(theDate)];
    theDate.add('days', 1);
  }

  return dates;
};

const getDurationHours = (from, to, existOccupation) =>
  Math.ceil(moment.duration(to.diff(from)).asHours()) +
  MIN_HOURS_BETWEEN_BOOKINGS +
  (existOccupation ? existOccupation.duration : 0);

const getStartOccupation = (startMoment, existOccupiedDays) => {
  const existDay = existOccupiedDays.find((o) => o.date.isSame(startMoment, 'day'));
  const endOfDay = moment(startMoment).endOf('day');
  const duration = getDurationHours(startMoment, endOfDay, existDay);

  return {
    date: startMoment,
    duration,
    type: duration < 24 ? OccupationTypes.END_OF_DAY : OccupationTypes.FULL_DAY,
  };
};

const getEndOccupation = (endMoment, existOccupiedDays) => {
  const existDay = existOccupiedDays.find((o) => o.date.isSame(endMoment, 'day'));
  const startOfDay = moment(endMoment).startOf('day');
  const duration = getDurationHours(startOfDay, endMoment, existDay);

  return {
    date: endMoment,
    duration,
    type: duration < 24 ? OccupationTypes.START_OF_DAY : OccupationTypes.FULL_DAY,
  };
};

const getOccupiedDays = (occupiedRanges) =>
  occupiedRanges.reduce((result, range) => {
    const startMoment = moment(range.from);
    const endMoment = moment(range.to).subtract(1, 'minutes'); // to exclude next day when equals end of day

    const existDays = result.filter(
      (o) => !o.date.isSame(startMoment, 'day') && !o.date.isSame(endMoment, 'day'),
    );

    if (!range.isBooking) {
      return [
        ...existDays,
        ...getFullDayDates(startMoment, endMoment).map((date) => ({
          date,
          duration: 24,
          type: OccupationTypes.DISABLED,
        })),
      ];
    }

    const startOccupation = getStartOccupation(startMoment, result);
    const endOccupation = getEndOccupation(endMoment, result);
    const fullDayOccupations = getFullDayDates(
      moment(startMoment).add(1, 'days').startOf('day'),
      moment(endMoment).startOf('day'),
    ).map((date) => ({
      date,
      duration: 24,
      type: OccupationTypes.FULL_DAY,
    }));

    return [...existDays, startOccupation, ...fullDayOccupations, endOccupation];
  }, []);

const isOccupiedRange = (start, end, occupiedDays) => {
  if (!occupiedDays || !occupiedDays.length) {
    return false;
  }

  const startMoment = start ? moment(start).endOf('day') : null;
  const endMoment = end ? moment(end).startOf('day') : null;

  return occupiedDays.some(
    (o) =>
      (o.type === OccupationTypes.DISABLED || o.type === OccupationTypes.FULL_DAY) &&
      (o.date.isBetween(startMoment, endMoment) ||
        o.date.isSame(startMoment, 'day') ||
        o.date.isSame(endMoment, 'day')),
  );
};

const isBookedRange = (start, end, occupiedDays) => {
  if (!occupiedDays || !occupiedDays.length) {
    return false;
  }

  const startMoment = start ? moment(start).startOf('day') : null;
  const endMoment = end ? moment(end).endOf('day') : null;

  return occupiedDays.some(
    (o) =>
      o.type !== OccupationTypes.DISABLED &&
      (o.date.isBetween(startMoment, endMoment) ||
        o.date.isSame(startMoment, 'day') ||
        o.date.isSame(endMoment, 'day')),
  );
};

const isBookedDate = (date, occupiedRanges) =>
  occupiedRanges.some((r) => r.isBooking && moment(date).isBetween(r.from, r.to));

const isDisabledDate = (date, occupiedRanges) =>
  occupiedRanges.some((r) => !r.isBooking && moment(date).isBetween(r.from, r.to));

const getCustomPriceDays = (priceRanges) =>
  priceRanges.reduce((result, range) => {
    const startMoment = moment(range.from);
    const endMoment = moment(range.to).subtract(1, 'minutes'); // to exclude next day when equals end of day

    const existDays = result.filter(
      (o) => !o.date.isSame(startMoment, 'day') && !o.date.isSame(endMoment, 'day'),
    );

    const rangeDays = getFullDayDates(
      moment(startMoment).startOf('day'),
      moment(endMoment).add(1, 'days').startOf('day'),
    ).map((date) => ({
      date,
      price: range.price,
    }));

    return [...existDays, ...rangeDays];
  }, []);

const rangeIncludesCustomPrice = (start, end, priceDays) => {
  if (!priceDays || !priceDays.length) {
    return false;
  }

  const startMoment = moment(start);
  const endMoment = moment(end);

  return priceDays.some(
    (o) =>
      o.date.isBetween(startMoment, endMoment) ||
      o.date.isSame(startMoment, 'day') ||
      o.date.isSame(endMoment, 'day'),
  );
};

export {
  DEFAULT_TRIP_LENGTH,
  DEFAULT_VEHICLE_UTC_OFFSET,
  MIN_HOURS_BETWEEN_BOOKINGS,
  BOOKING_NOTICE_PERIOD,
  OccupationTypes,
  getOccupiedDays,
  isOccupiedRange,
  isBookedRange,
  isBookedDate,
  isDisabledDate,
  getCustomPriceDays,
  rangeIncludesCustomPrice,
};
