const parse = (payoutRaw) =>
  payoutRaw
    ? {
        id: payoutRaw._id,
        icon: payoutRaw.type,
        ...payoutRaw,
      }
    : null;

export default {
  parse,
};
