import * as auth from './modules/auth';
import * as blog from './modules/blog';
import * as chat from './modules/chat';
import * as common from './modules/common';
import * as discountCode from './modules/discountCode';
import * as extraPayments from './modules/extraPayments';
import * as licence from './modules/licence';
import * as location from './modules/location';
import * as notification from './modules/notification';
import * as paymentMethod from './modules/paymentMethod';
import * as payout from './modules/payout';
import * as phone from './modules/phone';
import * as search from './modules/search';
import * as vehicle from './modules/vehicle';

export default {
  auth,
  blog,
  chat,
  common,
  discountCode,
  extraPayments,
  licence,
  location,
  notification,
  paymentMethod,
  payout,
  phone,
  search,
  vehicle,
};
