const styles = (theme) => ({
  backdrop: {
    zIndex: 2000,
    color: theme.palette.secondary.main,
    backgroundColor: '#fff',
  },
  transparentBackdrop: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  svg: {
    fill: 'none',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: '15px',
    margin: 'auto',
    width: '100px',
    height: '100px',
    opacity: '1.0',
  },
});

export default styles;
