import { darken, lighten } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {},
  colorPrimary: {
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.92),
    },
  },
  colorSecondary: {
    '&:hover': {
      backgroundColor: darken(theme.palette.secondary.main, 0.08),
    },
  },
});

export default styles;
