import axios from 'axios';

let axiosInstance;

export default async (config) => {
  if (!axiosInstance) {
    axiosInstance = axios.create({
      timeout: 30000,
    });
  }

  const headers = {
    ...config.headers,
  };

  const { method = 'get' } = config;

  try {
    const response =
      method === 'get' || method === 'delete'
        ? await axiosInstance[method](config.url, {
            headers,
            params: config.params,
            data: config.body,
          })
        : await axiosInstance[method](config.url, config.body, {
            headers,
            params: config.params,
          });

    return response.data;
  } catch (err) {
    if (config.errorWrapper) {
      throw config.errorWrapper(err);
    } else {
      throw err;
    }
  }
};
