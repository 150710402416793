const styles = (theme) => ({
  root: {
    height: '40px',
    lineHeight: '30px',
    fontSize: '13px',
    background: 'transparent',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    '&:hover $notchedOutline': {
      borderColor: theme.palette.border.main,
    },
    '&$focused $notchedOutline': {
      borderWidth: '1px',
      borderColor: theme.palette.border.active,
    },
  },
  multiline: {
    height: 'auto',
    padding: '8px 18px',
  },
  input: {
    padding: '12px 14px',
  },
  notchedOutline: {
    borderColor: theme.palette.border.main,
  },
  inputMultiline: {
    padding: 0,
    lineHeight: '24px',
  },
  focused: {},
});

export default styles;
