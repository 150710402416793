import * as auth from './modules/auth';
import * as core from './modules/core';
import * as maps from './modules/maps';
import * as places from './modules/places';

export default {
  auth,
  core,
  maps,
  places,
};
