import { googlePlace } from '@evee/evee-ui.models';

import { getApis } from './core';

export const getPlacePredictions = (search) =>
  new Promise((resolve) => {
    const { autocompleteService } = getApis();
    autocompleteService.getPlacePredictions({ input: search }, (result) => resolve(result));
  });

export const getPlaceDetails = (placeId) =>
  new Promise((resolve) => {
    const { placesService } = getApis();
    placesService.getDetails({ placeId }, (result) => resolve(result));
  }).then(googlePlace.parse);
