import * as basket from './modules/basket';
import * as cfClick from './modules/cfClick';
import * as creds from './modules/creds';
import * as discountCode from './modules/discountCode';
import * as latestSearch from './modules/latestSearch';
import * as subscribe from './modules/subscribe';
import * as token from './modules/token';
import * as user from './modules/user';

export default {
  basket,
  cfClick,
  creds,
  discountCode,
  latestSearch,
  subscribe,
  token,
  user,
};
