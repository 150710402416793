const getEmailFormatError = (value) => {
  const emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
  if (!emailRegex.test(value)) {
    return 'Please use correct e-mail address';
  }

  return null;
};

const getFormatError = (type, value) => {
  if (!type || !value) {
    return null;
  }

  if (type === 'email') {
    return getEmailFormatError(value);
  }

  return null;
};

export default getFormatError;
