import PropTypes from 'prop-types';
import React from 'react';

import { Box, ButtonBase, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const LinkButton = ({ text, disableTypography, classes, onClick, ...rest }) => (
  <ButtonBase
    disableRipple
    type="text"
    classes={{ root: classes.button, disabled: classes.disabled }}
    onClick={onClick}
    {...rest}
  >
    <Box display="flex" justifyContent="space-between">
      {disableTypography ? (
        text
      ) : (
        <Typography color="inherit" style={{ fontWeight: 'bold' }}>
          {text}
        </Typography>
      )}
    </Box>
  </ButtonBase>
);

LinkButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disableTypography: PropTypes.bool,
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    disabled: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};

LinkButton.defaultProps = {
  text: '',
  disableTypography: false,
  onClick: () => {},
};

export default withStyles(styles)(LinkButton);
