import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import MuiChip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const Chip = ({ selected, className, classes, ...rest }) => (
  <MuiChip
    color={selected ? 'secondary' : 'default'}
    className={clsx(classes.chip, className)}
    {...rest}
  />
);

Chip.propTypes = {
  selected: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.shape({
    chip: PropTypes.string.isRequired,
  }).isRequired,
};

Chip.defaultProps = {
  selected: false,
  className: '',
};

export default withStyles(styles)(Chip);
