const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  container: {
    zIndex: 1250, // must not exceed mui popover index of 1300
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: '0.5 !important',
    display: 'flex',
    justifyContent: 'center',
  },
  svg: {
    fill: 'none',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: '15px',
    margin: 'auto',
    width: '100px',
    height: '100px',
  },
};

export default styles;
