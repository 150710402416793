import * as yup from 'yup';

const parse = (extraPaymentRaw) =>
  extraPaymentRaw
    ? {
        id: extraPaymentRaw._id,
        ...extraPaymentRaw,
      }
    : null;

const parseDraft = (draftRaw) =>
  draftRaw
    ? {
        id: draftRaw._id,
        ...draftRaw,
      }
    : null;

const serialize = (extraPaymentRaw) => ({
  ...extraPaymentRaw,
  items: extraPaymentRaw.items.map((p) => ({ ...p, amount: Number(p.amount) })),
});

const requiredMsg = 'required';

const paymentSchema = yup.object().shape({
  name: yup.string().min(1).max(60).required(),
  amount: yup.number().positive().max(9999).required(),
});

const paymentsSchema = yup
  .array()
  .of(
    yup
      .object()
      .test('Is valid name', requiredMsg, (value) =>
        paymentSchema.fields.name.isValidSync(value.name),
      )
      .test('Is valid amount', requiredMsg, (value) =>
        paymentSchema.fields.amount.isValidSync(value.amount),
      ),
  )
  .min(1, requiredMsg);

export default {
  parse,
  parseDraft,
  serialize,
  paymentSchema,
  paymentsSchema,
};
