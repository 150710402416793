import { createErrorWrapper } from '../utils';

export const LOADING_ERROR = 'DISCOUNT_CODE.LOADING_ERROR';
export const CREATING_ERROR = 'DISCOUNT_CODE.CREATING_ERROR';
export const UPDATING_ERROR = 'DISCOUNT_CODE.UPDATING_ERROR';
export const DELETING_ERROR = 'DISCOUNT_CODE.DELETING_ERROR';

export const loadingErr = createErrorWrapper(LOADING_ERROR, 'Failed to load discount codes');
export const creatingErr = createErrorWrapper(CREATING_ERROR, 'Failed to create discount code');
export const updatingErr = createErrorWrapper(UPDATING_ERROR, 'Failed to update discount code');
export const deletingErr = createErrorWrapper(DELETING_ERROR, 'Failed to delete discount code');
