export default {
  aud: {
    id: 'AUD',
    name: 'Australian dollar',
    description: 'AUD - $',
  },
  nzd: {
    id: 'NZD',
    name: 'New Zealand dollar',
    description: 'NZD - $',
  },
};
